import fetch from "isomorphic-fetch";
import * as constants from "../presenters/Constants";
import { v4 as uuidv4 } from 'uuid';
import { isAppointOrchestratorEnabled } from "../utils";

export function apiLocationAppointments() {
    return window.env.REACT_APP_API_APPT_URL;
    //return "http://localhost:8080";
}

export function fetchFromAppointmentSlotsApi(dispatch, path, actionCreator, errorActionCreator, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment) {

    if(appointmentType && appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
        this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState, "nbnAppointmentDate": nbnAppointmentDate, "hardwareDeliveryDate": hardwareDeliveryDate, "evaluationID":evaluationID,"orderSystem":orderSystem, "portInFNN":portInFNN, "transactionReferenceID": transactionReferenceID, "appointment": appointment } };
    } else {
        this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState, "evaluationID":evaluationID,"orderSystem":orderSystem, "portInFNN":portInFNN, "transactionReferenceID": transactionReferenceID, "appointment": appointment } };
    }

    let orderCategory = localStorage.getItem('orderCategory');

    return fetch(apiLocationAppointments() + path + "?orderCategory=" + orderCategory, {
        headers: {
            'Content-Type': "application/json"
        },
        method: 'POST',
        body: JSON.stringify(this.state)
    })
        .then(response => {
            return response.json()
        })
        .then(json => {
            if (json && json.status) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else if (json.data.slots === undefined || json.data.slots === null) {
                    dispatch(errorActionCreator(json));
                } else {
                    dispatch(actionCreator(json));
                }
            } else {
                dispatch(errorActionCreator({"status":404}));
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator({"status":404}));
        })
}

export function fetchFromAppointmentSlotsApiAuth(dispatch, path, actionCreator, errorActionCreator, accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment) {

        if(appointmentType && appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
            this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState, "nbnAppointmentDate": nbnAppointmentDate, "hardwareDeliveryDate": hardwareDeliveryDate, "evaluationID":evaluationID,"orderSystem":orderSystem, "portInFNN":portInFNN, "transactionReferenceID": transactionReferenceID, "appointment": appointment } };
        } else {
            this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState, "evaluationID":evaluationID,"orderSystem":orderSystem, "portInFNN":portInFNN, "transactionReferenceID": transactionReferenceID, "appointment": appointment } };
        }

        let orderCategory = localStorage.getItem('orderCategory');

        return fetch(apiLocationAppointments() + path + "?orderCategory=" + orderCategory, {
            headers: {
                'Content-Type': "application/json",
                'Authorization': "Bearer "+accessToken
            },
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(response => {
                return response.json()
            })
            .then(json => {
                if (json && json.status) {
                    if (Math.floor(json.status / 100) !== 2) {
                        dispatch(errorActionCreator(json));
                    }
                    else if (json.data.slots === undefined || json.data.slots === null) {
                        dispatch(errorActionCreator(json));
                    } else {
                        dispatch(actionCreator(json));
                    }
                } else {
                    dispatch(errorActionCreator({"status":404}));
                }
            })
            .catch((ex) => {
                dispatch(errorActionCreator({"status":404}));
            })
    }

export function fetchFromAppointmentDetailsApi(dispatch, path, actionCreator, errorActionCreator, orderId) {
    let correlationId = uuidv4();
    console.log("Request correlation-id : ", correlationId);
    this.state = { "orderId":orderId};
    return fetch(apiLocationAppointments() + path, {
        headers: {
            'Content-Type': "application/json",
            'correlation-id' : correlationId
        },
        method: "POST",
        body: JSON.stringify(this.state)
    }).then(response => {
        return response.json()
    })
        .then(json => {
            if (json && json.status) {
                    if (Math.floor(json.status / 100) !== 2) {
                        dispatch(errorActionCreator(json));
                    } else if (json.data.appointments === undefined || json.data.appointments === null) {
                        dispatch(errorActionCreator(json));
                    } else {
                        dispatch(actionCreator(json));
                    }
            } else {
                dispatch(errorActionCreator({"status":403}));
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator({"status":404}));
        })
}
export function updateAppointmentConfirmApi(dispatch, path, actionCreator, errorActionCreator, orderId, appointmentType, appointmentId, deliveryType
    , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID) {
    let appointmentSlot = {};
    let orderCategory = localStorage.getItem('orderCategory');
    deliveryType = "Standard Install";

    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN) {
        if(orderSystem === constants.ORDER_SYSTEM_RCRM) {
            appointmentSlot = { "id": slotId};
        } else {
            appointmentSlot = { "date": date, "slotStartTime": slotStartTime, "slotEndTime": slotEndTime, "slotType": slotType, "timeZone": timezone};
            if(isAppointOrchestratorEnabled()) {
              appointmentSlot.id = slotId;
            }
        }
    }

    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
        appointmentSlot = { "id": slotId};
    }
    if(dualAppointments && dualAppointments !== undefined && dualAppointments.length === 0) {
        this.state = { "data": [{ "orderId":orderId, "appointmentId":appointmentId, "appointmentType": appointmentType, "deliveryType": deliveryType,  "appointmentSlot": appointmentSlot, evaluationID, orderSystem, portInFNN }] };
    } else {
        this.state = { "data": dualAppointments };
    }
    
    if(isAppointOrchestratorEnabled() && !!this.state.data) {
      this.state.data.forEach(a => a.transactionReferenceID = transactionReferenceID);
    }

    return fetch(apiLocationAppointments() + path + "/" + appointmentId + "?orderCategory=" + orderCategory, {
        headers: {
            'Content-Type': "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(this.state)
    }).then(response => {
        return response.json()
    })
        .then(json => {
            if (json && json.status) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else {
                    dispatch(actionCreator(json));
                }
            } else {
                dispatch(errorActionCreator({"status":404}));
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator({"status":404}));
        })

}

export function updateAppointmentConfirmApiAuth(dispatch, path, actionCreator, errorActionCreator, accessToken, orderId, appointmentType, appointmentId, deliveryType
    , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID) {
    let appointmentSlot = {};
    let orderCategory = localStorage.getItem('orderCategory');
    deliveryType = "Standard Install";
    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN) {
        if(orderSystem === constants.ORDER_SYSTEM_RCRM) {
            appointmentSlot = { "id": slotId};
        } else {
            appointmentSlot = { "date": date, "slotStartTime": slotStartTime, "slotEndTime": slotEndTime, "slotType": slotType, "timeZone": timezone};
            if(isAppointOrchestratorEnabled()) {
              appointmentSlot.id = slotId;
            }
        }
    }

    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
        appointmentSlot = { "id": slotId};
    }

    if(dualAppointments && dualAppointments !== undefined && dualAppointments.length === 0) {
        this.state = { "data": [{ "orderId":orderId, "appointmentId":appointmentId, "appointmentType": appointmentType, "deliveryType": deliveryType,  "appointmentSlot": appointmentSlot, evaluationID, orderSystem, portInFNN  }] };
    } else {
        this.state = { "data": dualAppointments };
    }

    if(isAppointOrchestratorEnabled() && !!this.state.data) {
      this.state.data.forEach(a => a.transactionReferenceID = transactionReferenceID);
    }
   
    return fetch(apiLocationAppointments() + path + "/" + appointmentId + "?orderCategory=" + orderCategory, {
        headers: {
            'Content-Type': "application/json",
            'Authorization': "Bearer "+accessToken
        },
        method: "PATCH",
        body: JSON.stringify(this.state)
    }).then(response => {
        return response.json()
    })
        .then(json => {
            if (json && json.status) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else {
                    dispatch(actionCreator(json));
                }
            } else {
                dispatch(errorActionCreator({"status":404}));
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator({"status":404}));
        })

}

export function fetchFromAppointmentDetailsApiAuth(dispatch, path, actionCreator, errorActionCreator, accessToken, orderId) {
    let correlationId = uuidv4();
    console.log("Request correlation-id : ", correlationId);
    this.state = { "orderId": orderId};
    return fetch(apiLocationAppointments() + path, {
        headers: {
            'Content-Type': "application/json",
            'Authorization': "Bearer "+accessToken,
            'correlation-id' : correlationId
        },
        method: "POST",
        body: JSON.stringify(this.state)
    }).then(response => {
        if (Math.floor(response.status / 100) !== 2) {
            dispatch(errorActionCreator({"status":response.status}));
            return false;
        }
        return response.json()
    }).then(json => {
        if (json && json.status) {
            if (json.data.appointments === undefined || json.data.appointments === null) {
                dispatch(errorActionCreator({"status":404}));
            } else {
                dispatch(actionCreator(json));
            }
        } else {
            dispatch(errorActionCreator({"status":401}));
        }           
    })
    .catch((ex) => {
        dispatch(errorActionCreator({"status":404}));
        })
}
