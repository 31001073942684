import React, {Component} from "react";
import sucessIcon from "../images/Success@1x.svg";
import {Column, Row} from "react-foundation-components/lib/global/grid";
import AppoinmentErrorMobile from "./AppointmentErrorMobile";
import Spinner from "../Spinner/Spinner";
import * as constants from "./Constants";
import {isValidUrl} from "../utils";
import * as analytics from "../analytics-utils";

class AppointmentRescheduleCompletedMobileView extends Component {
    constructor(props) {
        super(props);
        this.bypassCache = this.bypassCache.bind(this);
        let date = this.props.selectedAppointment.appointmentDate;
        let slotId = this.props.selectedAppointment.id;
        let slotStartTime=this.props.selectedAppointment.slotStartTime;
        let slotEndTime=this.props.selectedAppointment.slotEndTime;
        let slotType = slotStartTime && slotStartTime.split(" ") ? slotStartTime.split(" ").pop() : "AM";

        this.accessToken = this.props.accessToken && this.props.accessToken !== undefined ? this.props.accessToken : null;
        if(this.props.tle_error == null){
            const {evaluationID, orderSystem, portInFNN, transactionReferenceID} = this.props;
            if(this.accessToken) {
                this.props.updateAppointmentConfirmApiAuth(this.accessToken, this.props.orderId, this.props.selectedAppointment.appointmentType, this.props.selectedAppointment.appointmentId, this.props.deliveryType,
                    date, slotStartTime, slotEndTime, slotType, slotId, this.props.selectedAppointment.timezone, this.props.dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
            } else {
                this.props.updateAppointmentConfirmApi(this.props.orderId, this.props.selectedAppointment.appointmentType, this.props.selectedAppointment.appointmentId, this.props.deliveryType,
                    date, slotStartTime, slotEndTime, slotType, slotId, this.props.selectedAppointment.timezone, this.props.dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
            }
        }
         
    }
     
    render() {
        let spinnerMessage = '';
        if((this.props.selectedAppointment && this.props.selectedAppointment.appointmentType === 'TELSTRA') || (this.props.dualAppointments && this.props.dualAppointments.filter(appt=>appt.appointmentType==='TELSTRA').length>0)) {
            spinnerMessage = constants.APPT_WAIT_MSG;
        }
        if(this.props.tle_error != null){
            return <div>
                    <AppoinmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeApptErrorAndChangeAppt={this.props.closeApptErrorAndChangeAppt} openAppointmentDetailsMobileView={this.props.renderAppointmentPopup} errors={this.props.tle_error} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/>
                </div>
        }
        let apptAPIResp = this.props.selectedAppointment ? this.props.selectedAppointment : null;
        const {appointmentConfirm} = this.props;
        //uncomment when running on local
        // let status = 200;
        
        let status = appointmentConfirm && appointmentConfirm.status ? appointmentConfirm.status : null;
        
        
        if (appointmentConfirm.isFetching) {
            return <div> 
            <Row collapse expanded>
                <Column largeCentered="centered" large={8}>
                    <div className="telstra-spinner-box-mobile">
                        <div>
                            <Spinner size="large" message={spinnerMessage}/>
                        </div>
                    </div>
                </Column>
            </Row></div>
        }
        else {
            return <div>
                    {status && status === 200 ? this.renderAppointmentsData(apptAPIResp) : <AppoinmentErrorMobile closeApptErrorAndChangeAppt={this.props.closeApptErrorAndChangeAppt} displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} renderAppointmentSlotsPopup={this.props.renderAppointmentSlotsPopup} orderSystem={this.props.orderSystem} openAppointmentDetailsMobileView={this.props.renderAppointmentPopup} errors={appointmentConfirm.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/>}
                </div>
        }
         
    }
    renderAppointmentsData(apptAPIResp) {
        analytics.addOmnitureObjectForEventClick(constants.APPT_RESCHEDULE_SUCCESS_PAGE_NAME_ANALYTICS);
        return <Row collapse expanded className="no-margin">
        {apptAPIResp ?
            <div>
                
                    <div className = "appointment-manager-success-icon-div">
                        <img id="Appointment-Reschedule-Success-Icon" className="appointment-manager-sucess-icon" src={sucessIcon}/>
                    </div>
                    <div id="Appointment-Reschedule-Success-Message" className="appointment-manager-success-message-div">
                        <span className="appointment-manager-sucess-message">We’ve rescheduled your appointment</span>
                    </div>
                    <div id="Appointment-Reschedule-Email-Message" className="appointment-manager-success-email-message-div">
                        <span className="appointment-manager-success-email-message">{constants.APPPOINMENT_RESCHEDULE_SUCCESS_MSG}</span>
                    </div>
                    {this.props.displayCompleteCTA === 'false' ? null
                   :<div className="appointment-close-div-mobile" onClick={() => this.bypassCache()}>
                        <span id="done-text" className="appointment-close-button-label">Done</span>
                    </div>}
                </div>
            
            :<AppoinmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />

        }
        </Row>
    }
    bypassCache(){
        if (this.props.callBackUrl === 'ORDER_STATUS_TRACKER') {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId + '?overrideAppointment=true';
        } else if(isValidUrl(this.props.callBackUrl)) {
            window.location.href = this.props.callBackUrl + '?appointmentManagerStatus=SUCCESS';
        } else {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId + '?overrideAppointment=true';
        }
    }

}
export default AppointmentRescheduleCompletedMobileView;