import {connect} from "react-redux";
import Appointments from "../presenters/Appointments";
import {fetchAppointmentSlotsApi, fetchAppointmentSlotsApiAuth} from "../modules/appointmentSlots";
import {fetchAppointmentDetailsApi, fetchAppointmentDetailsApiAuth} from "../modules/appointmentDetails";
import {updateAppointmentConfirmApi, updateAppointmentConfirmApiAuth} from "../modules/appointmentConfirm";

function mapStateToProps(state) {
    return {
        appointmentSlots: state.appointmentSlots,
        appointmentDetails: state.appointmentDetails,
        appointmentConfirm: state.appointmentConfirm
    }
}
function mapDispatchToProps(dispatch) {
    return {
        fetchAppointmentSlotsApi:(appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment) => dispatch(fetchAppointmentSlotsApi(appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment)),
        fetchAppointmentSlotsApiAuth:(accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment) => dispatch(fetchAppointmentSlotsApiAuth(accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment)),
        fetchAppointmentDetailsApi:(orderId) => dispatch(fetchAppointmentDetailsApi(orderId)),
        updateAppointmentConfirmApi:(orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID) => dispatch(updateAppointmentConfirmApi(orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID)),
        updateAppointmentConfirmApiAuth:(accessToken, orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID) => dispatch(updateAppointmentConfirmApiAuth(accessToken, orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID)),
        fetchAppointmentDetailsApiAuth:(accessToken,orderId) => dispatch(fetchAppointmentDetailsApiAuth(accessToken, orderId))
    };
}

const ConnectedAppointments = connect(mapStateToProps, mapDispatchToProps)(Appointments);

export default ConnectedAppointments;
