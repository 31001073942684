import React, {Component} from "react";
import * as client from "../adapters/client";
import * as analytics from '../analytics-utils';
import * as constants from "../presenters/Constants";
import * as utils from "../utils";

const REQUEST_APPOINTMENT_SLOTS = 'REQUEST_APPOINTMENT_SLOTS';
const RECEIVE_APPOINTMENT_SLOTS = 'RECEIVE_APPOINTMENT_SLOTS';
const RECEIVE_APPOINTMENT_SLOTS_ERROR = 'RECEIVE_APPOINTMENT_SLOTS_ERROR';


class appointmentSlots extends Component {
    constructor(props) {
        super(props);
    }
}


export function requestAppointmentSlots() {
    return {
        type: REQUEST_APPOINTMENT_SLOTS
    }
}

export function receiveAppointmentSlots(json) {
    let actionObj = {
        type: RECEIVE_APPOINTMENT_SLOTS,        
        appointmentSlots: json.data ? json.data : null,
        errors: json.errors ? json.errors : null
    }
    return actionObj;
}

export function receiveAppointmentSlotError(json) {
    let errorMainMsg = constants.DEFAULT_ERROR_MAIN_MSG;
    const { errors } = json || {};
    if(errors && !utils.isEmpty(errors[0].message.message1)) {
        errorMainMsg = errors[0].message.message1;
    }
    analytics.createErrorEventObj(constants.APPT_ERROR_PAGE_NAME_ANALYTICS, 'error', 'Business', json.status, errorMainMsg);
   
    switch (json.status) {
        case 401:            
        case 403:            
        case 500:
        case 400:
        case 422:
        case 404:
        case 304:
        case 503:
        case 504:
            let actionObj = {
                type: RECEIVE_APPOINTMENT_SLOTS_ERROR,
                errors: json.errors ? json.errors : null
            }
            return actionObj;      
        default:
            return {type: null};
    }
}


export function fetchAppointmentSlotsApi( appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment, fetcher = client.fetchAppointmentSlots) {
    return (dispatch) => {
        dispatch(requestAppointmentSlots());
        fetcher(dispatch, receiveAppointmentSlots, receiveAppointmentSlotError, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment);
    }
}

export function fetchAppointmentSlotsApiAuth(accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment, fetcher = client.fetchAppointmentSlotsAuth) {
    return (dispatch) => {
        dispatch(requestAppointmentSlots());
        fetcher(dispatch, receiveAppointmentSlots, receiveAppointmentSlotError, accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment);
    }
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case REQUEST_APPOINTMENT_SLOTS:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isUnauthorized: false,
                errors: action.errors
            });
        case RECEIVE_APPOINTMENT_SLOTS:
            return Object.assign({}, state, {
                isFetching: false,
                appointmentSlots: action.appointmentSlots,
                errors: action.errors
            });
        case RECEIVE_APPOINTMENT_SLOTS_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                isUnauthorized: false,
                errors: action.errors                
            });     
       
        default:
            return state
    }
}