import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import appointmentDetails from "./modules/appointmentDetails";
import appointmentSlots from "./modules/appointmentSlots";
import appointmentConfirm from "./modules/appointmentConfirm";

const rootReducer = combineReducers({
    routing: routerReducer,
    appointmentDetails,
    appointmentSlots,
    appointmentConfirm
});

export default rootReducer