import React, {Component} from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import rightArrow from "../images/Blue_Arrow_1.5x.svg";
import * as analytics from "../analytics-utils";
import * as constants from "./Constants";

class NoAppointmentRescheduled extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {       
         
        return <Row  key="dualAppointmentConfirmDeatils" id="dualAppointmentConfirmDeatils" collapse expanded className="no-margin">
                <div>
                    <div id="appointment-manager-content" className="appointment-manager-content-no-appts">
                        <div id="dual-appointment-manager-details-header-div" className="appointment-manager-no-appts-header-div">
                            <div className="appointment-manager-no-appts-header">
                                <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-no-appts-heading">
                                    <span className="appointment-manager-no-appts-text-desktop">You have not rescheduled your nbn co or Telstra technician appointments and they will remain the same. </span><br></br>
                                </div>
                            </div>
                        </div>                        
                        <div>
                            <a id="dual-Confirm-Reschedule_appointment" className="reschedule-dual-text" onClick={(event) => this.viewAppointmentsPage()}>
                                <span className="reschedule-text">View appointments <img src={rightArrow} className="link-arrow-desktop" id="linkArrow"/></span>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </Row>
         
                
         
    }

    viewAppointmentsPage() {
        analytics.addOmnitureObjectForEventClick(constants.NO_APPT_RESCHEDULED_PAGE_NAME_ANALYTICS, constants.NO_APPT_RESCHEDULED_LINK_ANALYTICS, 'linkClick');
        this.props.viewAppointmentsLandingPage();
    }
    

}
export default NoAppointmentRescheduled;