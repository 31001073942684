// Woraround to only load telstra babel polyfill once
//global._babelPolyfill = false;
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {Route, Router} from "react-router";
import store, {history} from "./store";
import "@able/react/dist/able-react.min.css";
import "foundation-sites/dist/foundation.min.css";
import './index.css';
import './env.local';
import ConnectedAppointments from "./connectors/ConnectedAppointments";

ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Route path="/appointments" component={ConnectedAppointments}/>
        </Router>
    </Provider>,
  document.getElementById('root')
);
