import React from 'react';
import fetch from 'isomorphic-fetch';
import { browserHistory } from 'react-router';

let caimanLoginPath = window.env.REACT_APP_CAIMAN_API_BASE_URL;
let caimanClientId = window.env.REACT_APP_CLIENT_ID;
let redirectUrl = window.env.REACT_APP_HOST_NAME;
const crypto = require('crypto');

export function base64URLEncode(str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}

export function caimanLogin () {
    let verifier = base64URLEncode(crypto.randomBytes(32));
    localStorage.setItem('caiman_verifier', verifier);
    let challenge = base64URLEncode(sha256(verifier));
    window.location.href = caimanLoginPath + '/as/authorization.oauth2?response_type=code&client_id=' + caimanClientId + '&redirect_uri=' + redirectUrl + '&scope=openid hi b2c&code_challenge_method=S256&response_mode=query&code_challenge=' + challenge;
}

export function caimanLoginForSafari () {
    let verifier = base64URLEncode(crypto.randomBytes(32));
    localStorage.setItem('caiman_verifier', verifier);
    let challenge = base64URLEncode(sha256(verifier));
    window.location.href = caimanLoginPath + '/as/authorization.oauth2?response_type=code&client_id=' + caimanClientId + '&redirect_uri=' + redirectUrl + '&scope=openid hi b2c&code_challenge_method=S256&response_mode=query&code_challenge=' + challenge +'&prompt=none';
}

export function getAccessToken (code) {
    let params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('client_id', caimanClientId);
    params.append('redirect_uri', redirectUrl);
    params.append('code_verifier', localStorage.getItem('caiman_verifier'));

    let location = Object.assign({}, browserHistory.getCurrentLocation());
    delete location.query['code'];
    browserHistory.replace(location);
    return fetch(caimanLoginPath + '/as/token.oauth2', {
        method: 'post',
        body: params
    }).then(response => {
        return response.json();
    })
        .then(json => {
            if (json && json.access_token) {
                localStorage.setItem('accessToken', json.access_token);
                return json;
            } else {
                console.log('error in getting token');
                console.log(json);
            }
        })
        .catch((ex) => {
            console.log('error in getting token');
            console.log(ex);
        })
}