import React from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import * as constants from "./Constants";
import moment from "moment";
import AppoinmentErrorMobile from "./AppointmentErrorMobile";
import alertIcon from "../images/Alert Small@1x.svg";
import * as analytics from "../analytics-utils";
import { ActionButton, Surface, TextStyle} from '@able/react';

class AppointmentRescheduleConfirmMobile extends React.Component {
    constructor(props) {
        super(props);
        const { selectedAppointment } = this.props;
        const { appointmentType } = this.props;
        const { appointmentId } = this.props;
        const { euRequired } = this.props;
        const { date } = this.props;
        const { slotStartTime } = this.props;
        const { slotEndTime } = this.props;
        const { appt } = this.props;
        const { confirmInputReq } = this.props;
        this.state = { showApptSuccessful: false };
        const { slotType } = this.props;
    }

    render() {

        let apptAPIResp = this.props.selectedAppointment ? this.props.selectedAppointment : null;
        let dualAPIResp =  this.props.dualAppointments ? this.props.dualAppointments: null;
    
        const { appointmentConfirm } = this.props;
        return (
            <div>
                {dualAPIResp && dualAPIResp && dualAPIResp.length>0 ?
                   this.renderDualAppointmentsData(dualAPIResp)
                 : this.renderAppointmentsData(apptAPIResp)
                }
            </div>
        );

    }
    renderAppointmentsData(apptAPIResp) {
        let startTime = this.props.selectedAppointment.appointmentDate + ', ' + this.props.selectedAppointment.slotStartTime;
        let endTime = this.props.selectedAppointment.appointmentDate + ', ' + this.props.selectedAppointment.slotEndTime;
        return <Row collapse expanded className="no-margin">
            {apptAPIResp ?
                <div className="confirm-appt-mobile">

                    <div id="appointment-manager-content" className="appointment-manager-content">
                        <div id="appointment-manager-confirm-screen-header" className="appointment-manager-confirm-screen-header">
                            <span id="appointment-manager-second-confirm-heading" className="appointment-manager-second-confirm-heading">
                            {constants.CONFIRM_APPOINTMENT}
                            </span>
                        </div>

                        <div id="appointment-manager-change-date" className="appointment-manager-change-date">
                            <span className="appointmentTextLine">
                                Appointment
                            </span>
                            <br />
                            <div className="appointment-confirm-date-time-padding">
                            {this.props.selectedAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                            <span className="appointmentTextLines">
                                {this.props.selectedAppointment.appointmentType.charAt(0).toUpperCase()+this.props.selectedAppointment.appointmentType.toLowerCase().slice(1)} technician
                            </span>
                            :
                            <span className="appointmentTextLines">
                                {this.props.selectedAppointment.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}
                            </span>
                            }
                            </div>
                        </div>

                        <div id="appointment-manager-confirm-screen-header" className="appointment-manager-confirm-screen-header">
                            <span id="appointment-manager-second-confirm-headings" className="appointment-manager-second-confirm-headings">
                                {moment(this.props.selectedAppointment.appointmentDate).format('dddd D MMMM')}
                                <br />
                                {moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}
                            </span>

                        </div>

                        <div id="appointment-manager-change-dates" className="appointment-manager-change-dates">
                            <ActionButton id="cancelReschedule" className="cancel-reschedule" element="button" variant="LowEmphasis" label="Change" tabIndex="0"  onClick={(event) => this.changeAppointments(this.props.selectedAppointment.appointmentId)} />           
                        </div>


                        <div id="appointment-manager-slot-message">
                            <div>
                                <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-manager-slot-message-content-column">
                                            <img id="Service-Message-Icon" src={alertIcon} className="appointment-manager-slot-info-box" />
                                        </div>
                                        <div className="appointment-manager-slot-message-content-column">
                                            {this.props.selectedAppointment.euRequired || (this.props.selectedAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA) ?
                                            <span id="eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                            :
                                            <span id="non-eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                            }
                                        </div>
                                        </TextStyle>
                                        </Surface>
                            </div>
                            <div className="rectangle"></div>
                        </div>


                        <div id="appointment-manager-content" className="appointment-manager-slot-confirm-msgs">
                            <ActionButton element="button" variant="MediumEmphasis" label={constants.CONFIRM_APPOINTMENT} id="done-text" className="appointment-close-confirm-button-label" tabIndex="0"  onClick={(e) => this.confirmAppointment()} />
                        </div>


                        <div id="appointment-manager-change-dates" className="appointment-manager-change-dates">
                            <ActionButton element="button" variant="LowEmphasis" label="Cancel reschedule" className="dont-change-confirm-appt" tabIndex="0"  onClick={(e) => this.showOldAppointment()} />
                        </div>

                    </div>
                </div>
                : <AppoinmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />
            }
        </Row>


    }


    renderDualAppointmentsData(dualAPIResp) {
        let appointments = [];
        let counters = 1;

        if(this.props.appointmentDetails?.appointmentDetails?.appointments.length > 1 && dualAPIResp.length === 1 ) {
            const nbnAppointment = this.props.appointmentDetails.appointmentDetails.appointments[0];
            let unchangedNbnAppointment = {
                "appointmentId": nbnAppointment.appointmentId,
                "appointmentType": nbnAppointment.appointmentType,
                "euRequired": nbnAppointment.characteristics.euRequired,
                "appointmentSlot": {
                    "id": null,
                    "date": nbnAppointment.appointmentDetails.appointmentDate,
                    "slotStartTime": nbnAppointment.appointmentDetails.slotStartTime,
                    "slotEndTime": nbnAppointment.appointmentDetails.slotEndTime,
                    "slotType": (nbnAppointment.appointmentDetails.slotStartTime).split(" ").pop(),
                    "timeZone": nbnAppointment.appointmentDetails.timezone
                }
            };
            dualAPIResp.unshift(unchangedNbnAppointment);
        }
        
        appointments.push(<Row id="appointmentConfirmMobileDual" key="appointmentConfirmMobileDual" collapse expanded className="no-margin">
              <div id="appointment-manager-header" className="appointment-manager-confirm-header-dual-mobile">
                    <div id="appointment-manager-confirm-screen-header" className="appointment-manager-confirm-screen-header">
                                <span id="appointment-manager-second-confirm-heading" className="appointment-manager-second-confirm-headings-dual">
                                {constants.CONFIRM_APPOINTMENTS}
                                </span>
                    </div>
              </div>
        </Row>);

        if (dualAPIResp !== undefined) {
            dualAPIResp.forEach(e => {
                 appointments.push(
                    <Row key={e.appointmentType + counters} id="appointmentConfirmMobileDualContent" collapse expanded className="no-margin">
                        <div id="appointment-manager-content" className="appointment-manager-content-dual-mobile">
                             <div id="appointment-confirm-dual-appt-div" className="appointment-confirm-dual-appt-div">
                                <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading">
                                    <span className="appointment-manager-slots-slots-heading">Appointment </span> 
                                    <span className="appointment-manager-slots-slots-heading">{dualAPIResp.indexOf(e)+1} of </span>
                                    <span className="appointment-manager-slots-slots-heading">{dualAPIResp.length}</span>
                                     <br/>
                                     <div className="appointment-confirm-date-time-padding">
                                    {e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                                    <span className="appointmentTextLines">
                                        {e.appointmentType.charAt(0).toUpperCase()+e.appointmentType.toLowerCase().slice(1)} technician
                                    </span>
                                    :
                                    <span className="appointmentTextLines">
                                        {e.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}
                                    </span>
                                    }
                                    </div>
                                </div>
                                
                                <div id="appointment-manager-confirm-screen-header" className="appointment-manager-confirm-screen-header">
                                    <span id="appointment-manager-second-confirm-headings" className="appointment-manager-second-confirm-headings">
                                        {moment(e.appointmentSlot.date).format('dddd D MMMM')}
                                        <br />
                                        {moment(e.appointmentSlot.date + ', ' + e.appointmentSlot.slotStartTime).format('h:mma')} to {moment(e.appointmentSlot.date + ', ' + e.appointmentSlot.slotEndTime).format('h:mma')}
                                    </span>
                                </div>
                                 <div id="appointment-manager-change-dates" className="appointment-manager-change-dates">
                                        <ActionButton id="cancelReschedule" className="cancel-reschedule" element="button" variant="LowEmphasis" label="Change" tabIndex="0"  onClick={(event) => this.changeAppointments(e.appointmentId)} />
                                </div>
                                <div id="appointment-manager-slot-message">
                                    <div>
                                        <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-manager-slot-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-manager-slot-info-box" />
                                                </div>
                                                <div className="appointment-manager-slot-message-content-column">
                                                    {e.euRequired || (e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA) ?
                                                    <span id="eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                                    :
                                                    <span id="non-eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                                    }
                                                </div>
                                        </TextStyle>
                                        </Surface>
                                    </div>
                                    <div className="rectangle"></div>
                                </div>
                            </div>
                        </div>
                    </Row>);            
            })
             appointments.push(<Row id="appointmentConfirmMobileDualButtons" key="appointmentConfirmMobileDualButtons" collapse expanded className="no-margin">
              <div id="appointment-manager-content" className="appointment-manager-confirm-header-dual-mobile">
                <div id="appointment-manager-content" className="appointment-manager-slot-confirm-msgs-dual">
                    <ActionButton element="button" variant="MediumEmphasis" label={constants.CONFIRM_APPOINTMENTS} id="done-text" className="appointment-close-confirm-button-label" tabIndex="0"  onClick={(e) => this.confirmAppointment()} />
                </div>


                <div id="appointment-manager-change-dates" className="appointment-manager-change-dates">
                    <ActionButton element="button" variant="LowEmphasis" label="Cancel reschedule" className="dont-change-confirm-appt" tabIndex="0"  onClick={(e) => this.showOldAppointment()} />
                </div>
            </div>
        </Row>);

        return appointments
        }
        
        return <AppoinmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />;
    }

    confirmAppointment() {

        let date = this.props.selectedAppointment.appointmentDate;
        let slotStartTime = this.props.selectedAppointment.slotStartTime;
        let slotEndTime = this.props.selectedAppointment.slotEndTime;
        let slotId =  this.props.selectedAppointment.id ? this.props.selectedAppointment.id : null;
        let timezone = this.props.selectedAppointment.timezone ? this.props.selectedAppointment.timezone : null;

        analytics.addOmnitureObjectForEventClick(constants.APPT_CONFIRM_PAGE_NAME_ANALYTICS, constants.CONFIRM_APPT_CLICK_ANALYTICS, 'buttonClick');
        
        this.setState({ date: date, slotStartTime: slotStartTime, slotEndTime: slotEndTime, timezone: timezone });

        this.props.onCloseConfirmAppt();

    }


    showApptSlotsMobile() {
        this.props.onCloseDetailsMobile();
    }
    changeAppointments(apptId) {
        this.props.onCloseApptConfirmAndChangeAppt(apptId);
    }
    showOldAppointment() {
        this.props.onCloseConfirmApptKeepOld();
    }

}
export default AppointmentRescheduleConfirmMobile;