import _ from 'lodash';
import moment from "moment";
import React, { Component } from "react";
import { Row } from "react-foundation-components/lib/global/grid";
import * as analytics from "../analytics-utils";
import alertIcon from "../images/Alert Small@1x.svg";
import {isValidUrl, replaceMessageTextWithUrl} from '../utils';
import AppoinmentRescheduleError from "./AppoinmentRescheduleError";
import * as constants from "./Constants";
import { ActionButton, Surface, TextStyle} from '@able/react';

class AppointmentDetails extends Component {
    constructor(props) {
        super(props);
        this.renderAppointmentsData = this.renderAppointmentsData.bind(this);
       
        this.days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        this.month=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        this.state = {PONRReached : false};

        this.errors = {
            "errors": [
                {
                    "message": {
                        "message1": "As your appointment is in the next 24 hours, you can't reschedule it online.",
                        "message2": "To reschedule, please call 1800 931 877 and follow the nbn activations prompt. We're available Monday to Friday 7am to 9pm AEST and Saturday 8:30am to 5:30pm AEST.",
                        "message3": "Close"
                    },
                    "code": "400800"
                }
            ]
        };
        this.fetchAppointmentDetailsResponse = this.props.appointmentDetails;
        this.appointmentsData = this.fetchAppointmentDetailsResponse?.appointmentDetails;
       
    }
   
    render() {
            if(this.state.PONRReached){
                return <div> <AppoinmentRescheduleError displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.appointmentsData?.orderSystem} errors={this.fetchAppointmentDetailsResponse.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/> </div>
            }
            else{
                return <div>
                    {this.appointmentsData && this.appointmentsData?.appointments && this.appointmentsData.appointments.length > 0 ? this.renderAppointmentsData() : <AppoinmentRescheduleError displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.appointmentsData?.orderSystem} errors={this.fetchAppointmentDetailsResponse.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/>}
                </div>
            }
    }

    isMobileDevice() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if (navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {

        let devicelClassName = "popup-temp";
        if (this.isMobileDevice()) {
            devicelClassName = "popup-inner";
        } else if (this.isIpadDevice()) {
            devicelClassName = "popup-inner";
        }

        return devicelClassName;
    }

    renderAppointmentsData() {
        let totalCount = [];
        let appointments = [];
        let counters = 1;
        const { appointmentDetails } = this.props;
        
        const apptType = appointmentDetails.appointmentDetails.appointments.length === 1 ? appointmentDetails.appointmentDetails.appointments[0].appointmentType : 'NBN + Telstra';
        console.log(apptType);
        if (sessionStorage.getItem('apptType') !== null) {
            sessionStorage.removeItem('apptType');
        }
        sessionStorage.setItem('apptType', apptType);
        analytics.addOmnitureObjectForEventClick(constants.APPT_DETAILS_PAGE_NAME_ANALYTICS);

        if (this.appointmentsData && this.appointmentsData.appointments !== undefined && this.appointmentsData.appointments.length>0) {
            let currObj = null;
            appointments.push(<div>{this.appointmentsData.appointments.length>1 ?
            <span id="current-appointments-header" className="appointment-current-appointment-heading">
            {constants.CURRENT_APPOINTMENTS}
           </span>
           :
           <span id="current-appointments-header" className="appointment-current-appointment-heading">
            {constants.CURRENT_APPOINTMENT}
           </span>} </div>)
            this.appointmentsData.appointments.forEach(e => {
                currObj = e;
                let date = new Date(Date.parse(e.appointmentDetails.appointmentDate));
                let calendarDate = this.days[date.getDay()] + " " + date.getDate() + " " + this.month[date.getMonth()];
                let startTime = e.appointmentDetails.appointmentDate + ', ' + e.appointmentDetails.slotStartTime;
                let endTime = e.appointmentDetails.appointmentDate + ', ' + e.appointmentDetails.slotEndTime;

                const inlineErrorMsg = this.getInlineErrorMessage(e, this.fetchAppointmentDetailsResponse.orderSystem);

                counters = counters-1;
                appointments.push(
                    <Row key={e.appointmentType + counters} id="appointmentDeatils" collapse expanded className="no-margin">
                        <div className="appointment-details-border">
                            <div id="appointment-details-main-content-desktop" className="appointment-details-main-content-desktop">
                           <div id="appointment-manager-details-header-div" className="appointment-manager-details-header-div">
                            <div className="appointment-manager-slots-slot-header">
                                        {this.appointmentsData.appointments.length>1 ?
                                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-desktop">
                                                <span className="appointment-manager-details-subheading-desktop">Appointment </span> 
                                                <span className="appointment-manager-details-subheading-desktop">{this.appointmentsData.appointments.indexOf(e)+1} of </span>
                                                <span className="appointment-manager-details-subheading-desktop">{this.appointmentsData.appointments.length}</span>
                                            </div>
                                            :
                                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-desktop">
                                                Appointment
                                            </div>
                                        }
                                        {e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA ?
                                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-desktop-light">
                                                {e.appointmentType.charAt(0).toUpperCase() + e.appointmentType.toLowerCase().slice(1)} technician
                                            </div>
                                            : <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-desktop-light">
                                                {e.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}
                                            </div>
                                        }
                                    </div>
                                    </div>
                                <div id="appointment-details-date-desktop" className="appointment-details-date-desktop">
                                    {calendarDate}<br></br>
                                    {moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}
                                </div>
                                {
                                    inlineErrorMsg && (
                                        <div className="reschedule-inline-error">
                                            <span className="reschedule-ponr-text-desktop">{inlineErrorMsg}</span>
                                        </div>
                                    )
                                }
                                <div id="appointment-details-banner-message-div-desktop" className="appointment-details-banner-message-div-desktop">
                                    <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-details-banner-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                            </div>
                                            <div className="appointment-details-banner-message-content-column">
                                                    {(e.characteristics && e.characteristics.euRequired) || (e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA)?
                                                        <span id="appointment-details-banner-message-content-text" className="appointment-details-banner-message-content-text">
                                                            {constants.APPOINTMENT_RESCHEDULE_EU_MSG}
                                                        </span>
                                                        :
                                                        <span id="appointment-details-banner-message-content-text" className="appointment-details-banner-message-content-text">
                                                            {constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}
                                                        </span>
                                                    }
                                                </div>
                                        </TextStyle>
                                        </Surface>
                                    <div>
                                    </div>
                                    <div className="appointment-details-banner-message-info-rectangle"></div> 
                                </div>
                            </div>
                        </div>
                    </Row>)

                { counters++ }
            })
            appointments.push(this.showPONRMessage(currObj));
             
            return appointments;            
        }
        
        return <AppoinmentRescheduleError callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.appointmentsData.orderSystem} closeAppointmentCompleted={this.props.closeAppointmentCompleted} errors={this.fetchAppointmentDetailsResponse.errors}/>;
    }

    getInlineErrorMessage(appt, orderSystem) {
        let errorMsg = _.get(appt, 'errors[0].message.message1');
        const errorSecondaryMsg = _.get(appt, 'errors[0].message.message2');
        if (errorMsg && errorSecondaryMsg) {
            errorMsg += ' ' + errorSecondaryMsg;
        }
        
        if (errorMsg) {
            let messageUsUrl = (orderSystem && orderSystem === constants.ORDER_SYSTEM_RCRM) ? constants.MYTELSTRA_ONE_LINK : constants.MYTELSTRA_APP_URL;
            errorMsg = replaceMessageTextWithUrl(errorMsg, messageUsUrl);
        }

        return errorMsg;
    }

    showPONRMessage(e) {
        if (!e) return;

        if (this.appointmentsData.orderSystem !== constants.ORDER_SYSTEM_RCRM) {
            let date = new Date(Date.parse(e.appointmentDetails.appointmentDate));
            let calendarDate = this.days[date.getDay()] + " " + date.getDate() + " " + this.month[date.getMonth()];

            let todaysDate = new Date();
            let tomorrowsDate = new Date();
            tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
            todaysDate = new Date(Date.parse(todaysDate));
            tomorrowsDate = new Date(Date.parse(tomorrowsDate));

            var a = new Date(date);
            var b = new Date(tomorrowsDate);
            var diffDays = parseInt((b - a) / (1000 * 60 * 60 * 24), 10);

            let formattedTodaysDate = this.days[todaysDate.getDay()] + " " + todaysDate.getDate() + " " + this.month[todaysDate.getMonth()];
            let formattedTomorrowsDate = this.days[tomorrowsDate.getDay()] + " " + tomorrowsDate.getDate() + " " + this.month[tomorrowsDate.getMonth()];

            if (diffDays === 1) {
                return <div className="reschedule-ponr-div-desktop">
                    <span className="reschedule-ponr-text-desktop">{constants.PIK_ONLY_PONR1}</span>
                </div>
            }

            if (formattedTodaysDate === calendarDate) {
                return <div className="reschedule-ponr-div-desktop">
                    <span className="reschedule-ponr-text-desktop">{constants.PIK_ONLY_PONR2}</span>
                </div>
            }
            if (e.appointmentStatus && e.appointmentStatus.toLowerCase() === constants.COMPLETED) {
                return <div className="reschedule-ponr-div-desktop">
                    <span className="reschedule-ponr-text-desktop">{constants.PIK_ONLY_PONR3}</span>
                </div>
            }

            if (e.appointmentStatus && e.appointmentStatus.toLowerCase() === constants.CANCELLED) {
                return <div className="reschedule-ponr-div-desktop">
                    <span className="reschedule-ponr-text-desktop">{constants.PIK_ONLY_PONR4}</span>
                </div>
            }
        }
        // if all appointments have errors, then do not show the reschedule button
        if (this.appointmentsData.appointments.length === 1) {
            const errorMsg = _.get(this.appointmentsData, 'appointments[0].errors.0')
            if (!!errorMsg) return this.renderCloseButton();
        }

        if (this.appointmentsData.appointments.length === 2) {
            const errorMsg1 = _.get(this.appointmentsData, 'appointments[0].errors.0')
            const errorMsg2 = _.get(this.appointmentsData, 'appointments[1].errors.0')
            if (!!errorMsg1 && !!errorMsg2) return this.renderCloseButton();
        }

        return <div id="appointment-details-reschedule-button-div" className="appointment-details-reschedule-button-div">
            {this.appointmentsData.appointments.length>1 ?
            <div>
                <ActionButton element="button" variant="MediumEmphasis" label="Reschedule appointments" onClick={(event) => this.showApptSlotsPopupToggle(e)} className="appointment-details-reschedule-button-text" tabIndex="0" />
            </div>
            
            :
            <div>
                <ActionButton element="button" variant="MediumEmphasis" label="Reschedule appointment" onClick={(event) => this.showApptSlotsPopupToggle(e)} className="appointment-details-reschedule-button-text" tabIndex="0" />
            </div>
           }
        </div>
    }

    renderCloseButton() {
        if(this.props.displayCompleteCTA === 'false'){
            return null;
        } else {
            return <div id="appointment-details-reschedule-button-div" className="appointment-details-reschedule-button-div">
            <div>
                <ActionButton element="button" variant="MediumEmphasis" label="Close" onClick={() => this.closeAppointmentManager()} className="appointment-close-button-label-desktop" tabIndex="0" />
            </div>
            </div>;
        }
    }

    showApptSlotsPopupToggle(currentObj) {
        analytics.addOmnitureObjectForEventClick(constants.APPT_DETAILS_PAGE_NAME_ANALYTICS, constants.RESCHEDULE_APPT_CLICK_APPTS_ANALYTICS, 'buttonClick');
        let nonPONR = true;
            for (let appt = 0; appt < this.appointmentsData.appointments.length; appt++) {
                if(this.appointmentsData.appointments[appt].appointmentType && this.appointmentsData.appointments[appt].appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA && this.appointmentsData.appointments[appt].reschedulePONR === true) {
                    nonPONR = false;
                    this.setState({ PONRReached: true });
                    break;
                }
            }
        if(nonPONR) {
            if(this.appointmentsData.appointments.length > 1) {
                if(this.appointmentsData.appointments[0].reschedulePONR === true) {
                    this.props.onCloseDetails(this.appointmentsData.appointments[1]);
                } else {
                    this.props.dualApptConfirm(currentObj, this.appointmentsData, constants.APPT_CATEGORY_NBN);
                }
            } else {
                this.props.onCloseDetails(this.appointmentsData.appointments[0]);
            }
        }
    }

    closeAppointmentManager(){
        if (this.props.callBackUrl === 'ORDER_STATUS_TRACKER') {
            window.parent.postMessage(constants.APPT_RESCHEDULED_LISTENER, '*');
        } else if(isValidUrl(this.props.callBackUrl)) {
            window.location.href = this.props.callBackUrl + '?appointmentManagerStatus=UNSUCCESSFUL';
        } else {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId;
        }
    }
}
export default AppointmentDetails;
