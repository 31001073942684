import React, {Component} from "react";
import * as client from "../adapters/client";
import * as analytics from '../analytics-utils';
import * as constants from "../presenters/Constants";
import * as utils from "../utils";

const REQUEST_APPOINTMENT_CONFIRM = 'REQUEST_APPOINTMENT_CONFIRM';
const RECEIVE_APPOINTMENT_CONFIRM = 'RECEIVE_APPOINTMENT_CONFIRM';
const RECEIVE_APPOINTMENT_CONFIRM_ERROR = 'RECEIVE_APPOINTMENT_CONFIRM_ERROR';

class appointmentConfirm extends Component {
    constructor(props) {
        super(props);
    }
}

export function requestAppointmentConfirm() {
    return {
        type: REQUEST_APPOINTMENT_CONFIRM
    }
}

export function receiveAppointmentConfirm(json) {
    let actionObj = {
        type: RECEIVE_APPOINTMENT_CONFIRM,        
        appointmentConfirm: json.data ? json.data : null,
        errors: json.errors ? json.errors : null,
        appointmentStatus: json.status ? json.status : null
    }
    return actionObj;
}

export function receiveAppointmentConfirmError(json) {
    let errorMainMsg = constants.DEFAULT_ERROR_MAIN_MSG;
    const { errors } = json || {};
    if(errors && !utils.isEmpty(errors[0].message.message1)) {
        errorMainMsg = errors[0].message.message1;
    }
    analytics.createErrorEventObj(constants.APPT_ERROR_PAGE_NAME_ANALYTICS, 'error', 'Business', json.status, errorMainMsg);
   
    switch (json.status) {
        case 401:            
        case 403:            
        case 500:
        case 400:
        case 422:
        case 404:
        case 304:
        case 503:
        case 504:
            let actionObj = {
                type: RECEIVE_APPOINTMENT_CONFIRM_ERROR,
                errors: json.errors ? json.errors : null,
                appointmentStatus: json.status ? json.status : null
            } 
            return actionObj;    
        default:
            return {type: null};
    }
}


export function updateAppointmentConfirmApi(orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID, fetcher = client.updateAppointmentConfirm) {
    return (dispatch) => {
        dispatch(requestAppointmentConfirm());
        fetcher(dispatch, receiveAppointmentConfirm, receiveAppointmentConfirmError, orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
    }
}

export function updateAppointmentConfirmApiAuth(accessToken, orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID, fetcher = client.updateAppointmentConfirmAuth) {
    return (dispatch) => {
        dispatch(requestAppointmentConfirm());
        fetcher(dispatch, receiveAppointmentConfirm, receiveAppointmentConfirmError, accessToken, orderId, appointmentType, appointmentId,deliveryType,date,slotStartTime,slotEndTime,slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
    }
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case REQUEST_APPOINTMENT_CONFIRM:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isUnauthorized: false,
                errors: action.errors
            });
        case RECEIVE_APPOINTMENT_CONFIRM:
            return Object.assign({}, state, {
                isFetching: false,
                appointmentConfirm: action.appointmentConfirm,
                errors: action.errors,
                status: action.appointmentStatus
            });
        case RECEIVE_APPOINTMENT_CONFIRM_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                isUnauthorized: false,
                errors: action.errors,
                status: action.appointmentStatus
            });
       
        default:
            return state
    }
}