import React, {Component} from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import AppoinmentRescheduleError from "./AppoinmentRescheduleError";
import * as constants from "./Constants";
import alertIcon from "../images/Alert Small@1x.svg";
import moment from "moment";
import blueRightArrow from "../images/Blue_Arrow_1.5x.svg";
import * as analytics from "../analytics-utils";
import { ActionButton, Surface, TextStyle } from '@able/react';

class AppointmentConfirm extends Component {
    constructor(props) {
        super(props);
        const { selectedAppointment, appointmentType, appointmentId, euRequired, date, slotStartTime, slotEndTime, appt, confirmInputReq, slotType } = this.props;
        this.state = { showApptSuccessful: false };
        let selectedAppointmentForConfirm = this.props.selectedAppointment;
        this.appointmentDate = selectedAppointmentForConfirm && selectedAppointmentForConfirm.appointmentDate ? selectedAppointmentForConfirm.appointmentDate : null;
        this.startTime = this.appointmentDate ? this.appointmentDate + ', ' + selectedAppointmentForConfirm.slotStartTime : null;
        this.endTime = this.appointmentDate ? this.appointmentDate + ', ' + selectedAppointmentForConfirm.slotEndTime : null;
        this.slotId = selectedAppointmentForConfirm && selectedAppointmentForConfirm.id ? selectedAppointmentForConfirm.id : null;
    }

    render() {
        analytics.addOmnitureObjectForEventClick(constants.APPT_CONFIRM_PAGE_NAME_ANALYTICS);
        let apptAPIResp = this.props.selectedAppointment ? this.props.selectedAppointment : null;
        let dualAPIResp =  this.props.dualAppointments ? this.props.dualAppointments: null;

        return (
            <div>
                {dualAPIResp && dualAPIResp.length>0 ?
                   this.renderDualAppointmentsData(dualAPIResp)
                 : this.renderAppointmentsData(apptAPIResp)
                }
            </div>
        );

    }

    renderAppointmentsData(apptAPIResp) {

        return <Row collapse expanded className="no-margin" >
            {apptAPIResp ?
                <div className="confirm-appt-desktop">
                    <Row id="appointmentDetails" collapse expanded >
                        <div className="confirm-heading-div">
                            <span id ="confirm-heading-text" className="confirm-heading-text">{constants.CONFIRM_APPOINTMENT}</span>
                        </div>
                        <div className="">
                            <div id="appointment-confirm-content" className="appt-confirm-content-desktop-conainer">
                                <span className="confirm-appt-title-confirm-appt-text">Appointment</span>
                                <div className="confirm-appt-subtitle-div">
                                     {this.props.selectedAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                                        <span className="appointment-confirm-appt-category">{this.props.selectedAppointment.appointmentType.charAt(0).toUpperCase()+this.props.selectedAppointment.appointmentType.toLowerCase().slice(1)} technician</span>
                                     : <span className="appointment-confirm-appt-category">{this.props.selectedAppointment.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}</span>
                                     }
                                </div>
                                <div id="appointment-slot-div" className="confirm-appointment-slot-div">
                                    {this.appointmentDate ? moment(this.appointmentDate).format('dddd D MMMM') : null}
                                    <br />
                                    {this.startTime ? moment(this.startTime).format('h:mma') + " to " + moment(this.endTime).format('h:mma') : null}
                                </div>
                                <div id="appointment-manager-change-dates" className="appointment-manager-change-dates-dual-desktop">
                                        <ActionButton element="button" variant="LowEmphasis" label="Change" tabIndex="0"  onClick={(event) => this.changeAppointments(this.props.selectedAppointment.appointmentId)} />
                                </div>
                                <br />
                            </div>

                        </div>


                        <div id="appointment-manager-content" className="non-eu-msg-container-desktop">
                            <Surface
                                variant="SurfaceSlight"
                                className="eg-surface appointment-details-banner-message-content"
                                interactive={undefined}
                                id="non-eu-msg"
                            >
                                <TextStyle
                                    alias="TextBigBodyShort"
                                    element="p"
                                    colour="Default"
                                    alignment="Left"
                                    className=""
                                >
                                    <div className="appointment-details-banner-message-content-column">
                                        <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                    </div>
                                    <div className="appointment-details-banner-message-content-column">
                                        {this.props.selectedAppointment.euRequired || (this.props.selectedAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA) ?
                                        <span className="appointment-details-banner-message-content-text">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                        :
                                        <span className="appointment-details-banner-message-content-text">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                        }
                                    </div>
                                </TextStyle>
                            </Surface>
                        </div>
                        <div className="appointment-details-banner-message-info-rectangle"></div>
                        <div>
                            <ActionButton style={{ marginTop: 40}} element="button" variant="MediumEmphasis" label={constants.CONFIRM_APPOINTMENT} className="confirm-appt-btn" id="Confirm_appointment" tabIndex="0"  onClick={(e) => this.confirmAppointment()} />
                        </div>
                        <div className="dont-change-div">
                            <ActionButton element="button" variant="LowEmphasis" label="Cancel reschedule" className="dont-change-appt-btn" id="Dont-change-appt" tabIndex="0"  onClick={(e) => this.showOldAppointment()} />
                        </div>
                    </Row>
                </div>
                :
                <AppoinmentRescheduleError displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.props.orderSystem} openAppointmentDetailsPopup={this.props.openAppointmentDetailsPopup} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />
            }
        </Row>
    }

    renderDualAppointmentsData(dualAPIResp) {
        let appointments = [];
        let counters = 1;

        if(this.props.appointmentDetails?.appointmentDetails?.appointments.length > 1 && dualAPIResp.length === 1 ) {
            const nbnAppointment = this.props.appointmentDetails.appointmentDetails.appointments[0];
            let unchangedNbnAppointment = {
                "appointmentId": nbnAppointment.appointmentId,
                "appointmentType": nbnAppointment.appointmentType,
                "euRequired": nbnAppointment.characteristics.euRequired,
                "appointmentSlot": {
                    "id": null,
                    "date": nbnAppointment.appointmentDetails.appointmentDate,
                    "slotStartTime": nbnAppointment.appointmentDetails.slotStartTime,
                    "slotEndTime": nbnAppointment.appointmentDetails.slotEndTime,
                    "slotType": (nbnAppointment.appointmentDetails.slotStartTime).split(" ").pop(),
                    "timeZone": nbnAppointment.appointmentDetails.timezone
                }
            };
            dualAPIResp.unshift(unchangedNbnAppointment);
        }
        
        appointments.push( <Row collapse expanded className="no-margin" >
                <div className="confirm-appt-desktop">
                    <Row key="apptConfirmHeader" id="appointmentDetails" collapse expanded >
                        <div className="confirm-heading-div-dual">
                                <span id="confirm-heading-text" className="confirm-heading-text">{constants.CONFIRM_APPOINTMENTS}</span>
                        </div>
                     </Row>
              </div>
       </Row>);



        if (dualAPIResp !== undefined) {
            dualAPIResp.forEach(e => {
                 appointments.push(
                    <Row key={e.appointmentType + counters} id="appointmentConfirmMobileDualContent" collapse expanded className="no-margin">
                        <div id="appointment-manager-content" className="appointment-manager-content-dual">
                             <div id="appointment-manager-change-date" className="appointment-confirm-dual-appt-div">
                                <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading">
                                    <span className="confirm-appt-title confirm-appt-text">Appointment </span> 
                                    <span className="confirm-appt-title confirm-appt-text">{dualAPIResp.indexOf(e)+1} of </span>
                                    <span className="confirm-appt-title confirm-appt-text">{dualAPIResp.length}</span>
                                     <br/>
                                    {e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                                    <div className="appointmentTextLinesDualDesktop">{e.appointmentType.charAt(0).toUpperCase()+e.appointmentType.toLowerCase().slice(1)} technician</div>
                                    :
                                    <div className="appointmentTextLinesDualDesktop">{e.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}</div>
                                    }
                                </div>
                                
                                <div id="appointment-manager-confirm-screen-header" className="appointment-manager-date-time-slot" >
                                    <span id="appointment-manager-second-confirm-headings" className="confirm-appointment-slot-div">
                                        {moment(e.appointmentSlot.date).format('dddd D MMMM')}
                                        <br />
                                        {moment(e.appointmentSlot.date + ', ' + e.appointmentSlot.slotStartTime).format('h:mma')} to {moment(e.appointmentSlot.date + ', ' + e.appointmentSlot.slotEndTime).format('h:mma')}
                                    </span>
                                </div>
                                 <div id="appointment-manager-change-dates" className="appointment-manager-change-dates-dual-desktop">
                                        <ActionButton element="button" variant="LowEmphasis" label="Change" tabIndex="0"  onClick={(event) => this.changeAppointments(e.appointmentId)} />
                                </div>
                                <div id="appointment-manager-content" className="non-eu-msg-container-desktop">
                                    <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-details-banner-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                            </div>
                                            <div className="appointment-details-banner-message-content-column">
                                                    {e.euRequired || (e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA) ?
                                                
                                                    <span className="appointment-details-banner-message-content-text">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                                    :
                                                    <span className="appointment-details-banner-message-content-text">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                                }
                                                </div>
                                        </TextStyle>
                                        </Surface>
                                    <div className="appointment-details-banner-message-info-rectangle"></div>
                                </div>
                            </div>
                        </div>
                    </Row>);            
            })
             appointments.push(<Row id="appointmentConfirmMobileDualButtons" key="appointmentConfirmMobileDualButtons" collapse expanded className="no-margin appointment-manager-content-dual-button">
                <div>
                    <ActionButton element="button" variant="MediumEmphasis" label={constants.CONFIRM_APPOINTMENTS} className="confirm-appt-btn" id="Confirm_appointment" tabIndex="0"  onClick={(e) => this.confirmAppointment()} />
                </div>
                <div className="dont-change-div">
                    <ActionButton element="button" variant="LowEmphasis" label="Cancel reschedule" className="dont-change-appt-btn" id="Dont-change-appt" tabIndex="0"  onClick={(e) => this.showOldAppointment()} />
                </div>
            </Row>);

        return appointments
        }
        
        return <AppoinmentRescheduleError displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.props.orderSystem} openAppointmentDetailsPopup={this.props.openAppointmentDetailsPopup} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />
    }

    confirmAppointment() {
        analytics.addOmnitureObjectForEventClick(constants.APPT_CONFIRM_PAGE_NAME_ANALYTICS, constants.CONFIRM_APPT_CLICK_ANALYTICS, 'buttonClick');
        this.props.onCloseConfirmAppt();
    }

    showOldAppointment() {
        analytics.addOmnitureObjectForEventClick(constants.APPT_CONFIRM_PAGE_NAME_ANALYTICS, constants.CANCLE_RESCHEDULE_ANALYTICS, 'linkClick');
        this.props.onCloseConfirmApptKeepOld();
    }
    changeAppointments(apptId) {
        this.props.onCloseApptConfirmAndChangeAppt(apptId);
    }

    isMobileDevice() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if (navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {

        let devicelClassName = "popup-temp desktop-layout";
        if (this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if (this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }



}
export default AppointmentConfirm;
