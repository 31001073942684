import React from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import * as constants from "./Constants";
import moment from "moment";
import alertIcon from "../images/Alert Small@1x.svg";
import rightArrow from "../images/Blue_Arrow_1.5x.svg";
import * as analytics from "../analytics-utils";
import { ActionButton, Surface, TextStyle} from '@able/react';

class DualAppointmentConfirm extends React.Component {   
    constructor(props) {
        super(props);  
        this.renderDualAppoinmentData = this.renderDualAppoinmentData.bind(this);
    }

    render() {
        const { currentAppointment, allAppointments, apptType } = this.props;
        let dualAppointmentObj = null;
        if(apptType.toLowerCase() === constants.APPT_CATEGORY_NBN) {
            dualAppointmentObj = allAppointments.appointments[0] ? allAppointments.appointments[0] : null;
        } else {
            dualAppointmentObj = allAppointments.appointments[1] ? allAppointments.appointments[1] : null;
        }
        
       return <div>
                {this.renderDualAppoinmentData(dualAppointmentObj, allAppointments)}
            </div>
    }

    renderDualAppoinmentData(currentAppointment, allAppointments){
        if(currentAppointment === null || currentAppointment === undefined){
            return <React.Fragment></React.Fragment>
        }
        const pageName = currentAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA ? constants.APPT_RESCHEDULE_TELSTRA_PAGE_NAME_ANALYTICS : constants.APPT_RESCHEDULE_NBN_PAGE_NAME_ANALYTICS;
        analytics.addOmnitureObjectForEventClick(pageName);
        let appointments = [];
        //currentAppointment.appointmentDetails.slotStartTime
        let startTime = currentAppointment.appointmentDetails.appointmentDate + ', ' + currentAppointment.appointmentDetails.slotStartTime;
        let endTime = currentAppointment.appointmentDetails.appointmentDate + ', ' + currentAppointment.appointmentDetails.slotEndTime;
         appointments.push(
             <Row  key="dualAppointmentConfirmDeatils" id="dualAppointmentConfirmDeatils" collapse expanded className="no-margin">
                        <div>
                            <div id="appointment-manager-content" className="appointment-manager-content-slots">
                                <div id="dual-appointment-manager-details-header-div" className="appointment-manager-details-header-div">
                                    <div className="appointment-manager-slots-slot-header">
                                        <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading-dual">
                                            <span className="appointment-manager-slots-slots-heading-dual">Do you want to reschedule your </span><br></br>
                                                {currentAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                                                   <span className="appointment-manager-slots-slots-heading-dual">{currentAppointment.appointmentType.charAt(0).toUpperCase()+currentAppointment.appointmentType.toLowerCase().slice(1)} technician appointment?</span>
                                                :  <span className="appointment-manager-slots-slots-heading-dual">{currentAppointment.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT} appointment?</span>
                                                }
                                        </div>
                                        
                                    </div>
                                </div>    
                                <div id="dual-appointment-manager-details-type-div" className="appointment-manager-details-type-div">
                                    <div className="appointment-manager-slots-slot-header">
                                            <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading">
                                                <span className="appointment-manager-details-subheading-desktop">Appointment </span> 
                                                <span className="appointment-manager-details-subheading-desktop">{allAppointments.appointments.indexOf(currentAppointment)+1} of </span>
                                                <span className="appointment-manager-details-subheading-desktop">{allAppointments.appointments.length}</span>
                                            </div>
                                            
                                            {currentAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                                            <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading-light">
                                                {currentAppointment.appointmentType.charAt(0).toUpperCase()+currentAppointment.appointmentType.toLowerCase().slice(1)} technician
                                            </div>
                                        :   <div id="   -appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading-light">
                                                {currentAppointment.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}
                                            </div>
                                         }
                                    </div>
                                </div>
                                <div id="dual-appt-Confirm-slot-div" className="appointment-details-date-desktop">
                                    {currentAppointment.appointmentDetails.appointmentDate}<br></br>
                                    {moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}
                                </div>

                                {allAppointments.appointments.indexOf(currentAppointment) == 0 ?
                                <div id="appointment-details-banner-message-div-desktop" className="appointment-details-banner-message-div-desktop-dual">
                                    <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-details-banner-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                            </div>
                                            <div className="appointment-details-banner-message-content-column">
                                                    <span id="appointment-details-banner-message-content-text" className="appointment-details-banner-message-content-text">
                                                            {constants.CHANGE_SECOND_APPT}
                                                        </span>
                                                </div>
                                        </TextStyle>
                                        </Surface>
                                    <div>
                                    </div>
                                    <div className="appointment-details-banner-message-info-rectangle"></div> 
                                </div>
                                :""}
                                <div id="dual-Confirm-Reschedule_appointment-div" className="appointment-resc-reschedule-button-div">
                                    <ActionButton element="button" variant="LowEmphasis" label="Yes, reschedule" onClick={(event) => this.closeDualAppointment(currentAppointment, allAppointments)} tabIndex="0" />

                                </div>
                                <div id="dual-Confirm-Reschedule_appointment-div">
                                    <ActionButton element="button" variant="LowEmphasis" label="No, keep this appointment" onClick={(e) => {this.props.dualApptConfirm(currentAppointment, allAppointments, constants.APPT_CATEGORY_TELSTRA);this.keepDualAppt(currentAppointment)}} tabIndex="0" />
                                </div>
                            </div>
                        </div>
                    </Row>
         );
         return appointments;
    } 

    isMobileDevice() {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if(navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {
        
        let devicelClassName = "popup-inner desktop-layout";
        if(this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if(this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }

 closeDualAppointment(currentObj, apptAPIResp) {
        const pageName = currentObj.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA ? constants.APPT_RESCHEDULE_TELSTRA_PAGE_NAME_ANALYTICS : constants.APPT_RESCHEDULE_NBN_PAGE_NAME_ANALYTICS;
        analytics.addOmnitureObjectForEventClick(pageName, constants.YES_RESCHEDULE_CLICK_ANALYTICS, 'linkClick');
        this.props.closeDualAppointmentConfirm(currentObj,apptAPIResp)
    }

    keepDualAppt(currentAppointment){
        const pageName = currentAppointment.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA ? constants.APPT_RESCHEDULE_TELSTRA_PAGE_NAME_ANALYTICS : constants.APPT_RESCHEDULE_NBN_PAGE_NAME_ANALYTICS;
        analytics.addOmnitureObjectForEventClick(pageName, constants.NO_KEEP_APPT_CLICK_ANALYTICS, 'linkClick');
    }

    // showOldAppointment() {
    //     this.props.onCloseConfirmApptKeepOld();
    // }

}    
 

export default DualAppointmentConfirm;