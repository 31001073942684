import React, {Component} from "react";
import Calendar from 'react-calendar';
import moment from "moment";
import * as utils from "../utils";

class AppointmentManagerCalendarPopup extends Component {
    constructor(props) {
        super(props);      
        this.isMobileDevice = this.isMobileDevice.bind(this);
        this.isIpadDevice = this.isIpadDevice.bind(this);
        this.getClassNameForDevice = this.getClassNameForDevice.bind(this);
        this.state = {
            date: new Date(), dateChanged: false
        }
    }
    render() {
        var earliestRescheduleDate = utils.addDays(new Date(), 0);
        if(this.props.earliestRescheduleDate){
            earliestRescheduleDate = new Date(Date.parse(this.props.earliestRescheduleDate));
        }
        let futureDate = new Date(moment().add(3,'months').calendar());
        return (
            <div>
                <div className="calendar-main-div-mobile">
                    <div onClick={this.props.closePopup} id="u20" className="cl1">
                        <div id="u20_div" className="mouseOver cl2" tabIndex="0"></div>
                        <div id="u20_text" className="text cl3">
                            <p><span>X</span></p>
                        </div>
                    </div>

                    <div id="appointment-manager-slots-calendar-header" className="appointment-manager-slots-calendar-header">
                        <span id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-calendar-heading">
                            Choose a date to see availability
                        </span>
                    </div>

                    <div className="calendar-mobile-view">
                    <Calendar onChange={this.onChange}
                        tileDisabled={({date, view }) => (date.getDay()===6 || date.getDay()===0)}  
                        minDate={earliestRescheduleDate}                     
                        value={this.state.date}
                        maxDate={futureDate}
                    />
                    </div>

                    {/* <div id="appointment-manager-change-date" className="appointment-manager-calendar-date">
                        {
                            !this.state.dateChanged ? 
                            <span id="calendarOpenText" className="calendarOpenLink-calendar-date-text1">
                                Current date
                            </span>
                            :
                            <span id="calendarOpenText" className="calendarOpenLink-calendar-date-text1">
                                Date selected
                            </span>
                        }
                    </div>

                    <div id="appointment-manager-change-date" className="appointment-manager-calendar-date">
                        <span id="calendarOpenText" className="calendarOpenLink-calendar-date-text2">
                            {moment(this.state.date.toLocaleString()).format('DD MMM, YYYY')}
                        </span>
                    </div>

                    <div id="appointment-manager-change-date" className="appointment-manager-calendar-date-button-div">
                        <div className="appointment-manager-calendar-date-button" >
                            <span id="done-text" className="appointment-manager-calendar-date-button-text">Find slots</span>
                        </div>
                    </div> */}

                </div>
                <div className="popup" onClick={this.props.closePopup}>
                </div>
            </div>
        );
    }

    onChange = date => this.props.changeDate(date);

    isMobileDevice() {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if(navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {
        
        let devicelClassName = "popup-inner desktop-layout";
        if(this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if(this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }
}
export default AppointmentManagerCalendarPopup;