import React from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import rightArrow from "../images/Blue_Arrow_1.5x.svg";
import * as analytics from "../analytics-utils";
import * as constants from "./Constants";

class NoAppointmentRescheduledMobile extends React.Component {   
    constructor(props) {
        super(props);  
    }

    render() {
       

        return <Row  key="dualAppointmentConfirmDeatils" id="dualAppointmentConfirmDeatils" collapse expanded className="no-margin">
                <div>
                    <div id="appointment-manager-content" className="appointment-manager-content-no-appts">
                        <div id="dual-appointment-manager-details-header-div" className="appointment-manager-no-appts-header-div-mobile">
                            <div className="appointment-manager-no-appts-header">
                                <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-no-appts-heading">
                                    <span className="appointment-manager-no-appts-text">You have not rescheduled your nbn co or Telstra technician appointments and they will remain the same. </span><br></br>
                                </div>
                            </div>
                        </div>                        
                        <div>
                            <a id="dual-Confirm-Reschedule_appointment" className="reschedule-dual-text" onClick={(event) => this.props.viewAppointmentsPage()}>
                                <span className="reschedule-text-mobile">View appointments <img src={rightArrow} className="link-arrow-desktop" id="linkArrow"/></span>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </Row>
    }

   
    
    isMobileDevice() {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if(navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {
        
        let devicelClassName = "popup-inner desktop-layout";
        if(this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if(this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }

    closeDualAppointment(currentObj, apptAPIResp) {
        this.props.closeDualAppointmentConfirm(currentObj,apptAPIResp)
    }

    viewAppointmentsPage() {
        analytics.addOmnitureObjectForEventClick(constants.NO_APPT_RESCHEDULED_PAGE_NAME_ANALYTICS, constants.NO_APPT_RESCHEDULED_LINK_ANALYTICS, 'linkClick');
        this.props.viewAppointmentsLandingPage();
    }
    // showOldAppointment() {
    //     this.props.onCloseConfirmApptKeepOld();
    // }

}    
 

export default NoAppointmentRescheduledMobile;