import React, {Component} from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import * as constants from "./Constants";
import serverError from "../images/server-error-1x.svg";
import {isValidUrl, replaceMessageTextWithUrl, openChat} from "../utils";
import * as analytics from '../analytics-utils';
import { ActionButton } from '@able/react';

class AppoinmentRescheduleError extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        const { errors, orderSystem } = this.props;
        this.orderCategory = localStorage.getItem('orderCategory');
        let errorMainMsg = constants.DEFAULT_ERROR_MAIN_MSG;
        let errorSecondaryMsg = null;
        let errorButtonMessage = constants.APPOINTMENT_MANAGER_ERROR_PAGE_BUTTON;    
        if (errors && errors[0] && errors[0].code && errors[0].message && errors[0].message.message1) {
            errorMainMsg = errors[0].message.message1;
            errorSecondaryMsg = errors[0].message.message2;
            errorButtonMessage = errors[0].message.message3;
            let MESSAGE_US = (orderSystem && orderSystem === constants.ORDER_SYSTEM_RCRM) ? constants.MYTELSTRA_ONE_LINK : constants.MYTELSTRA_APP_URL;
            errorSecondaryMsg = replaceMessageTextWithUrl(errorSecondaryMsg, MESSAGE_US);
        }
        return (
            <Row id="appointment-reschedule-error" collapse expanded className="no-margin">
                <div id="appointment-error-main-content-desktop" className="appointment-error-main-content-desktop">
                    <div id="appointment-error-main-photo-div-desktop" className="appointment-error-main-photo-div-desktop">
                        <img id="reschedule_appointment-error" className="appointment-error-image" src={serverError} />
                    </div>
                    <div id="appointment-error-main-msg-div-desktop" className="appointment-error-main-msg-div-desktop">
                        <span className="appointment-error-main-msg-heading-desktop">{errorMainMsg}</span>
                    </div>
                    {errorSecondaryMsg ? <div id="appointment-error-secondary-msg-div-desktop" className="appointment-error-secondary-msg-div-desktop">
                        <span className="appointment-error-secondary-msg-heading-desktop">{errorSecondaryMsg}</span>
                    </div> : null }
                    {this.renderErrorMessages(errorButtonMessage)}

                </div>
            </Row>
        );
    }

    renderErrorMessages(errorButtonMessage) {       
        
        if (errorButtonMessage) {
            switch (errorButtonMessage.toLowerCase()) {
                case constants.CLOSE_BTN:
                    if(this.props.displayCompleteCTA === 'false')
                        return null;
                    else{
                        return <div>
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.closeAppointmentManager()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                        </div>
                    }
                    break;
                case constants.GO_BACK:
                    if(this.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_FIXED)
                        return <div>
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.props.closeApptErrorAndChangeAppt()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                        </div>
                    else {
                        return <div>
                        {!this.props.dateChanged &&
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() =>  window.location.reload()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                        }
                            <ActionButton element="button" variant="MediumEmphasis" label='kjkjj' onClick={() => this.props.closeApptErrorAndResetDate()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                        </div>
                    }
                    break;
                case constants.VIEW_SLOTS_BTN:
                        return <div>
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.props.closeApptErrorAndChangeAppt()} className={errorButtonMessage.toLowerCase() === constants.VIEW_SLOTS_BTN ? "view-available-appointment-button-label-desktop" : "appointment-error-close-button-label-desktop"} tabIndex="0" />
                        </div>
                    
                    break;
                case constants.MESSAGE_US_BTN:
                        return <div>
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => openChat()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                        </div>
                    
                    break;
                default:
                    if(this.props.displayCompleteCTA === 'false')
                        return null;
                    else{
                        return <div>
                                <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.closeAppointmentManager()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                            </div>
                    }
                    break;
            }
        }
        if(this.props.displayCompleteCTA === 'false')
            return null
        else{
            return <div>
                        <ActionButton element="button" variant="MediumEmphasis" label="Close" onClick={() => this.closeAppointmentManager()} className="appointment-error-close-button-label-desktop" tabIndex="0" />
                    </div>
        }
    }

    closeAppointmentManager(){
        if (this.props.callBackUrl === 'ORDER_STATUS_TRACKER') {
            window.parent.postMessage(constants.APPT_RESCHEDULED_LISTENER, '*');
        } else if(isValidUrl(this.props.callBackUrl)) {
            window.location.href = this.props.callBackUrl + '?appointmentManagerStatus=UNSUCCESSFUL';
        } else {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId;
        }
    }

}
export default AppoinmentRescheduleError;