import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Animation from '../Animation';
import SpinnerJson from './assets/SpinnerData.json';
import * as utils from "../../utils";

/**
 * The brand specific animated loading spinner
 *
 * @since 1.4.0
 *
 * Updates:
 *
 - `2.2.0` updated the colouring to match new branding
 */
const Spinner = props => {
  const componentClass = 'mt-spinner';
  const classes = classnames(
    componentClass,
    `${componentClass}--${props.size}`
  );
  const message = `${props.message ? props.message : ''}`;

  if (utils.isMobileDevice() || utils.isIpadDevice()) {
    return (<div>
      <Animation className={classes} data={SpinnerJson} loop={true} />
      {message}
    </div>);
  } else {
    return (<div>
      <Animation className={classes} data={SpinnerJson} loop={true} />
      <div className="spinner-message-desktop">{message}</div>
    </div>);
  }
};

Spinner.propTypes = {
  /**
   * Size of spinner
   */
  size: PropTypes.oneOf(['medium', 'large', 'small', 'mini']).isRequired,
};

Spinner.defaultProps = {
  size: 'medium',
};

export default Spinner;
