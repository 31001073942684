import * as apiFetch from "./apiFetch";

export function fetchAppointmentDetails(dispatch, receiveAppointmentDetails, receiveAppointmentDetailsError, orderId, fetcher = apiFetch) {
    fetcher.fetchFromAppointmentDetailsApi(dispatch, '/v1/appointment-manager/fetch-appointments', receiveAppointmentDetails, receiveAppointmentDetailsError, orderId);
}

export function fetchAppointmentDetailsAuth(dispatch, receiveAppointmentDetails, receiveAppointmentDetailsError, accessToken, orderId, fetcher = apiFetch) {
    fetcher.fetchFromAppointmentDetailsApiAuth(dispatch, '/v1/appointment-manager/fetch-appointments', receiveAppointmentDetails, receiveAppointmentDetailsError, accessToken, orderId);
}

export function fetchAppointmentSlots(dispatch, receiveAppointmentSlots, receiveAppointmentSlotsError, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment, fetcher = apiFetch) {
    fetcher.fetchFromAppointmentSlotsApi(dispatch, '/v1/appointment-manager/fetch-slots', receiveAppointmentSlots, receiveAppointmentSlotsError, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate,evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment);
    //dispatch(receiveOrderError(500));
}

export function fetchAppointmentSlotsAuth( dispatch, receiveAppointmentSlots, receiveAppointmentSlotsError, accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate, evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment, fetcher = apiFetch) {
    fetcher.fetchFromAppointmentSlotsApiAuth(dispatch, '/v1/appointment-manager/fetch-slots', receiveAppointmentSlots, receiveAppointmentSlotsError, accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate,evaluationID, orderSystem, portInFNN, transactionReferenceID, appointment);
    //dispatch(receiveOrderError(500));
}

export function updateAppointmentConfirm(dispatch, receiveAppointmentDetails, receiveAppointmentDetailsError, orderId, appointmentType, appointmentId, deliveryType
    , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID, fetcher = apiFetch) {
    fetcher.updateAppointmentConfirmApi(dispatch, '/v1/appointment-manager/appointments', receiveAppointmentDetails, receiveAppointmentDetailsError, orderId, appointmentType, appointmentId, deliveryType
        , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
}

export function updateAppointmentConfirmAuth(dispatch, receiveAppointmentDetails, receiveAppointmentDetailsError,accessToken, orderId, appointmentType, appointmentId, deliveryType
    , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID, fetcher = apiFetch) {
    fetcher.updateAppointmentConfirmApiAuth(dispatch, '/v1/appointment-manager/appointments', receiveAppointmentDetails, receiveAppointmentDetailsError, accessToken, orderId, appointmentType, appointmentId, deliveryType
        , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
}
