import React, {Component} from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import * as constants from "./Constants";
import apptErrorImg from "../images/Appt-error-img.svg";
import {isValidUrl, replaceMessageTextWithUrl, openInNewTab} from "../utils";
import * as analytics from '../analytics-utils';
import { ActionButton } from '@able/react';

class AppointmentErrorMobile extends Component {
    constructor(props) {
        super(props);
        this.orderCategory = localStorage.getItem('orderCategory');
    }
    closeAppointmentCompleted() {
        if (this.props.callBackUrl === 'ORDER_STATUS_TRACKER') {
            this.props.closeAppointmentCompleted();
        } else if(isValidUrl(this.props.callBackUrl)) {
            window.location.href = this.props.callBackUrl + '?appointmentManagerStatus=UNSUCCESSFUL';
        } else {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId;
        }
    }

    render() {
        const { errors, orderSystem } = this.props;
        let errorMainMsg = constants.DEFAULT_ERROR_MAIN_MSG;
        let errorSecondaryMsg = null;
        let errorButtonMessage = constants.APPOINTMENT_MANAGER_ERROR_PAGE_BUTTON;
        if (errors && errors[0] && errors[0].code && errors[0].message && errors[0].message.message1) {
            errorMainMsg = errors[0].message.message1;
            errorSecondaryMsg = errors[0].message.message2;
            errorButtonMessage = errors[0].message.message3;

            let MESSAGE_US = (orderSystem && orderSystem === constants.ORDER_SYSTEM_RCRM) ? constants.MYTELSTRA_ONE_LINK : constants.MYTELSTRA_APP_URL;
            errorSecondaryMsg = replaceMessageTextWithUrl(errorSecondaryMsg, MESSAGE_US);
        }

        return <Row collapse expanded className="no-margin">
            <div id="appointment-manager-error" className="appointment-manager-error-logo">
                <img id="Error-Logo" className="error-logo" src={apptErrorImg} alt="Error Logo" />
            </div>
            <div className="appointment-error">
                {this.renderErrorMessages(errorMainMsg, errorSecondaryMsg, errorButtonMessage)}
                {this.renderErrorButton(errorButtonMessage)}
            </div>
        </Row>
    }

    renderErrorMessages(errorMainMsg, errorSecondaryMsg, errorButtonMessage) {
        if (errorButtonMessage && errorButtonMessage.toLowerCase() == constants.VIEW_SLOTS_BTN) {
            return <div>
                <div className="view-appt-appointment-error-msg">
                    {errorMainMsg}
                </div>
                {
                    errorSecondaryMsg ?
                        <div className="view-appointment-error-sub-text">
                            {errorSecondaryMsg}
                        </div> : null
                }
            </div>
        }
        else{
            return <div>
                <div className="appointment-error-msg">
                    {errorMainMsg}
                </div>
                {
                    errorSecondaryMsg ?
                        <div className="appointment-error-sub-text">
                            {errorSecondaryMsg}
                        </div> : null
                }
            </div>
        }
    }

 renderErrorButton(errorButtonMessage) {
        if (errorButtonMessage) {
            switch (errorButtonMessage.toLowerCase()) {
                case constants.CLOSE_BTN:
                    if(this.props.displayCompleteCTA === 'false')
                        return null;
                    else{
                        return <div className="appointment-error-close-div-mobile">
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.closeAppointmentCompleted()} className="appointment-error-close-button-label-mobile" tabIndex="0" />
                        </div>
                    }
                    break;
                case constants.GO_BACK:
                    if(this.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_FIXED)
                        return <div className="appointment-error-close-div-mobile">
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.props.closeApptErrorAndChangeAppt()} className="appointment-error-close-button-label-mobile" tabIndex="0" />
                        </div>
                    else {
                        return <div className="appointment-error-close-div-mobile">
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.props.closeApptErrorAndResetDate()} className="appointment-error-close-button-label-mobile" tabIndex="0" />
                        </div>
                    }
                    break;
                case constants.VIEW_SLOTS_BTN:
                    return <div id="appointment-manager-header" className="view-available-appt-error-mobile-button">
                                <ActionButton element="button" variant="LowEmphasis" label={errorButtonMessage} tabIndex="0"  onClick={() => this.props.closeApptErrorAndChangeAppt()} className="show-available-appointments-error-button"/>
                            </div>
                    break;
                    case constants.MESSAGE_US_BTN:
                        return <div className="appointment-error-message-us-div-mobile">
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={(e) => openInNewTab(e,constants.MESSAGE_US_URL)} className="appointment-error-close-button-label-mobile" tabIndex="0" />
                        </div>
                    
                    break;
                default:
                    if(this.props.displayCompleteCTA === 'false')
                        return null;
                    else{
                        return <div className="appointment-error-close-div-mobile">
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.closeAppointmentCompleted()} className="appointment-error-close-button-label-mobile" tabIndex="0" />
                        </div>
                    }
                    break;
            }
        }
        if(this.props.displayCompleteCTA === 'false')
            return null;
        else{
            return <div className="appointment-error-close-div-mobile">
                            <ActionButton element="button" variant="MediumEmphasis" label={errorButtonMessage} onClick={() => this.closeAppointmentCompleted()} className="appointment-error-close-button-label-mobile" tabIndex="0" />
                        </div>
        }

    }
}
export default AppointmentErrorMobile;