export const NBN_DETAILS_TITLE = "nbn co technician appointment"
export const RESCHEDULE_APPT = "Reschedule appointment";
export const NBN_RESCHEDULE_APPT_MSG = "If you reschedule your appointment, it will take up to 24 hours for the new details to be shown here.";
export const RESCHEDULE_APPT_MSG = "If you reschedule your appointment, it will take up to 24 hours for the new details to be shown here.";
export const IN_PROGRESS = "in progress";
export const COMPLETED = "completed";
export const CANCELLED =  "cancelled";
export const FAILED ="Failed"
export const COMPLETE = "complete";
export const ON_HOLD = "on hold";
export const QUEUED = "queued";
export const UNABLE_TO_RESCHEDULE = "We're unable to reschedule this appointment online";
export const STEP_SHIPMENT_DETAILS = "shipmentDetails";
export const STEP_NBN_APPT_DETAILS ="nbnAppointmentDetails";
export const STEP_TELSTRA_APPT_DETAILS ="appointments";
export const STEP_DISCONNECTION_DETAILS ="disconnectionDetails";
export const UNAUTH_RESCHEDULABLE_MSG = 'This order number is not linked to your account OR you need to be the authorised account holder to make changes. Please call us on <a href="tel:1800834273">1800 834 273</a> and follow the Check Order prompt. We’re available from 7am to 9pm AEST.';
export const AUTH_RESCHEDULABLE_MSG = "Unfortunately we’re unable to reschedule your appointment online. Please call us on {<a href='tel:1800834273'>1800 834 273</a>} and follow the Check Order prompt. We’re available from 7am to 9pm AEST.";
export const EU = "EU";
export const NON_EU = "NON EU";
export const ORDER_CATEGORY_NBN = "nbn";
export const ORDER_CATEGORY_TELSTRA = "telstra";
export const ORDER_CATEGORY_MOBILE_PREORDER = "mobile_pre_order";
export const ORDER_CATEGORY_MOBILE_POSTPAID = "mobile postpaid online shop";
export const ORDER_CATEGORY_SEMI_SUPPORTED = "semi-supported";
export const ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX = "nbn_";
export const ORDER_CATEGORY_EOV = "mobile subscription";
export const ORDER_CATEGORY_PREPAID = "prepaid";
export const ORDER_CATEGORY_SEMI_SUPPORTED_SUBSCRIPTION = "semi supported subscription";
export const ORDER_CATEGORY_SIM_SWAP_DISPATCH = "sim swap dispatch";
export const TELSTRA_PROFESSIONAL_INSTALL = "Telstra professional installation appointment";
export const APPT_REQUIRED = "Remember, someone over 18 needs to be home.";
export const INSTALLATION_MSG = "How to prepare for your Telstra professional installation appointment";
export const CONNECTION_DATE_UNAVAILABLE = "Your connection date is yet to be determined.";
export const CALENDAR_LABEL = "Add to your calendar";
export const STARTRACK = "StarTrack";
export const AUS_POST = "Australia Post";
export const BROADBAND_ADSL = "broadband adsl";
export const BROADBAND_VELOCITY = "broadband velocity";
export const TELSTRA_ORDER_CATEGORIES_ARR = ["broadband adsl", "broadband velocity", "broadband cable", "telstra", "pstn","platinum"];
export const BROADBAND_CABLE = "broadband cable";
export const PSTN = "pstn";
export const PLATINUM = "platinum";
export const HEADLESS = "headless";
export const NEXTGEN_OSM = "nextgen osm";
export const NEXTGEN_OSM_HDW = "nextgen osm hdw";
export const HEADLESS_PREORDER = "headless preorder";
export const HIDE_DATE_ACTION = "hide date";
export const TRACK_DELIVERY = "Track your delivery";
export const UNHEALTHY = "unhealthy";
export const TRADEMARK_SYMB = "(TM)";
export const RED = "red";
export const GREEN = "green";
export const AMBER = "amber";
export const HARDWARE_DELIVERY = "hardware delivery";
export const ORDER_COMPLETE = "order complete";
export const ORDER_COMPLETED = "order completed";
export const ORDER_PROCESSED = "order created";
export const ORDER_CANCELLED = "order cancelled";
export const SHIPMENT_BACKORDERED = "backordered";
export const NBN_SIEBEL_SEMISUPPORTED = "NBN_SIEBEL_SEMISUPPORTED";
export const NBN_SIEBEL_SEMISUPPORTED_HEADER = "Your order is in progress";
export const SHIPMENT_ICONS_ARR = ["ok", "problem", "attention", "in progress"];
export const SSO_DEV_APP_NAME = "order tracker"
export const SHIPMENT_ATTENTION = "attention";
export const ORDER_COMPLETE_MILESTONE_LABEL = "Order complete";
export const ORDER_CANCELLED_MILESTONE_LABEL = "Order cancelled";
export const HEADLESS_RESCHEDULE_APPT = "Call 1800 931 877 to reschedule your appointment"
export const DEFAULT_APPOINTMENT_SLOTS = '4';
export const APPOINTMENT_RESCHEDULE_EU_MSG = "Someone age 18 or over must be home for this appointment";
export const APPOINTMENT_RESCHEDULE_NON_EU_MSG = "You don't need to be home for this appointment";
export const APPPOINMENT_RESCHEDULE_ERROR_MSG_1 = "Sorry we are unable to reschedule this appointment online appointment at this time.";
export const APPPOINMENT_RESCHEDULE_ERROR_MSG_2 = "If you still need to reschedule please call 13 XXX XXX";
export const APPPOINMENT_RESCHEDULE_UNAUTHRISED_ERROR_MSG_1 = "Unable to reschedule";
export const APPPOINMENT_RESCHEDULE_UNAUTHRISED_ERROR_MSG_2 = "This order number is not linked to your account OR you need to be the authorised account holder to make changes. To change your appointment, please <message us>";
export const APPT_DETAILS = "appointment_details";
export const APPT_SLOTS = "appointment_slots";
export const DEFAULT_ERROR_MAIN_MSG = "There was a technical problem. Please try again later.";
export const APPOINTMENT_MANAGER_ERROR_PAGE_BUTTON = "Close";
export const APPPOINMENT_RESCHEDULE_SUCCESS_HEADER = "We’ve rescheduled your appointment";
export const APPPOINMENT_RESCHEDULE_SUCCESS_MSG = "You’ll receive a notification confirming your new appointment details shortly.";
export const COLLECTION_BANNER_MSG = "Sorry we missed you. Check the delivery card we left or the StarTrack link below for pick up details.";
export const COLLECTION_BANNER_HEADER = "Hardware delivery update";
export const BACKORDERED_TYPE = "backordered";
export const COLLECTION_TYPE = "awaiting collection";
export const CARD_BANNER_MSG = "Our courier wasn't able to deliver your hardware but has left a card for it to be collected. Follow the StarTrack link below for pick up details.";
export const CARD_BANNER_HEADER = "Hardware delivery update";
export const CARDED = "delivery attempted";
export const ERROR_MESSAGE_1 = "Please ensure the order ID you have entered is correct and try again.";
export const ERROR_MESSAGE_2 = "If your order was placed in the last 24 hours, it may not yet be available in our systems.";
export const ERROR_MESSAGE_COVID = "Due to COVID-19 impacts we are experiencing order delays. We will notify you once your order has progressed. We apologise for the inconvenience.";
export const APPT_CATEGORY_NBN = "nbn";
export const APPT_CATEGORY_TELSTRA = "telstra";
export const ORDER_SYSTEM_RCRM = "RCRM";
export const MYTELSTRA_APP_URL = "https://www.telstra.com.au/mytelstra";
export const MYTELSTRA_ONE_LINK = "https://mytelstra.onelink.me/BJRB/nbnactivationOST";
export const PIK_ONLY_PONR1 = "You can’t reschedule your Telstra technician appointment online as it is occurring within the next 24 hours. To reschedule call 1800 931 877";
export const PIK_ONLY_PONR2 = "You can’t reschedule your Telstra technician appointment online as it is occurring today. To get help call 1800 931 877";
export const PIK_ONLY_PONR3 = "Your Telstra technician appointment has already occurred.";
export const PIK_ONLY_PONR4 = "The appointment has been cancelled.";
export const VIEW_SLOTS_BTN = "view available appointments";
export const CLOSE_BTN = "close";
export const CHANGE_SECOND_APPT = "You may need to change the 2nd appointment if you change this.";
export const RESCHEDULE_TELSTRA_APPT_MSG = "This appointment must be rescheduled a minimum of 2 days after your nbn co technician appointment";
export const NBN_CO_TECHNICIAN_TEXT = "co technician";
export const APPT_RESCHEDULED_LISTENER = "appointment rescheduled";
export const APPT_OVERRIDDEN_LISTENER = "appointment overridden";
export const COVID_TELL_ME_MORE = "https://www.telstra.com.au/covid19?ti=TR:TR:17032020:persnew:news:notifbar:def:cvr:top:DCAHD-4921:cvd19";
export const SIGN_IN_TEXT = "Sign in  >";
export const SIGN_OUT_TEXT = "Sign out  >";
export const TOKEN_EXPIRED_LISTENER = "expired";
//export const TOKEN_EXPIRING_LISTENER = "expiring";
export const NUMBER_TRANSFER = "phone number transfer";
export const SERVICE_ACTIVATION = "service activation";


export const CURRENT_APPOINTMENTS="Current appointments";
export const CURRENT_APPOINTMENT="Current appointment";
export const CONFIRM_APPOINTMENT="Confirm appointment";
export const CONFIRM_APPOINTMENTS="Confirm appointments";
export const GO_BACK="go back";
export const CLOSE_APPT_LISTENER = "close appointment";


export const RESCHEDULE_APPT_CLICK_APPTS_ANALYTICS = "Rechedule Appointments";
export const CONFIRM_APPT_CLICK_ANALYTICS = "Confrim Appointment";
export const YES_RESCHEDULE_CLICK_ANALYTICS = "Yes, reschedule now";
export const NO_KEEP_APPT_CLICK_ANALYTICS = "No, keep this appointment";
export const CANCLE_RESCHEDULE_ANALYTICS = "Cancel reschedule";
export const NO_APPT_RESCHEDULED_LINK_ANALYTICS = "View Appointments";

export const APPT_DETAILS_PAGE_NAME_ANALYTICS = "Appointment Details";
export const APPT_RESCHEDULE_NBN_PAGE_NAME_ANALYTICS = "Reschedule NBN";
export const APPT_RESCHEDULE_TELSTRA_PAGE_NAME_ANALYTICS = "Reschedule Telstra";
export const APPT_AVLBL_NBN_SLOTS_PAGE_NAME_ANALYTICS = "Available NBN slots";
export const APPT_AVLBL_TELSTRA_SLOTS_PAGE_NAME_ANALYTICS = "Available Telstra slots";
export const APPT_MANDATORY_TELSTRA_RESCHEDULE_PAGE_NAME_ANALYTICS = "Mandatory Telstra reschedule";
export const APPT_CONFIRM_PAGE_NAME_ANALYTICS = "Appointment Confirm";
export const APPT_RESCHEDULE_SUCCESS_PAGE_NAME_ANALYTICS = "Reschedule Success";
export const APPT_ERROR_PAGE_NAME_ANALYTICS = "Appointment Error";
export const APPT_WAIT_MSG = "Sit tight, this may take a minute.";
export const NO_APPT_RESCHEDULED_PAGE_NAME_ANALYTICS = "View Appointments";
export const TRANSFER_TYPE_LIST = ['Connect Outstanding External In', 'Connect Outstanding External In - Port In', 'Service Transfer In - Port In', 'Service Transfer In'];

export const ORDER_CATEGORY_FIXED = "fixed";
export const MESSAGE_US_BTN = "message us";
export const MESSAGE_US_URL = "https://open.mytelstra.app/BJRB/nbnactivate";