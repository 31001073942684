import React from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import * as constants from "./Constants";
import moment from "moment";
import alertIcon from "../images/Alert Small@1x.svg";
import rightArrow from "../images/Blue_Arrow_1.5x.svg";
import * as analytics from "../analytics-utils";
import { ActionButton, Surface, TextStyle } from '@able/react';;

class TelstraAppointmentReschedule extends React.Component {   
    constructor(props) {
        super(props);  
        this.renderDualAppoinmentData = this.renderDualAppoinmentData.bind(this);
    }

    closeAppointmentCompleted(){
        analytics.addOmnitureObjectForEventClick(constants.APPT_MANDATORY_TELSTRA_RESCHEDULE_PAGE_NAME_ANALYTICS, constants.CANCLE_RESCHEDULE_ANALYTICS, 'linkClick');
        this.props.closeAppointmentCompleted();
    }

    render() {
        const { currentAppointment, allAppointments, apptType } = this.props;
        let dualAppointmentObj = null;
        if(apptType.toLowerCase() === constants.APPT_CATEGORY_NBN) {
            dualAppointmentObj = allAppointments.appointments[0] ? allAppointments.appointments[0] : null;
        } else {
            dualAppointmentObj = allAppointments.appointments[1] ? allAppointments.appointments[1] : null;
        }
        analytics.addOmnitureObjectForEventClick(constants.APPT_MANDATORY_TELSTRA_RESCHEDULE_PAGE_NAME_ANALYTICS);
        
       return <div>
                {this.renderDualAppoinmentData(dualAppointmentObj, allAppointments)}
            </div>
    }

    renderDualAppoinmentData(currentAppointment, allAppointments){
        if(currentAppointment === null || currentAppointment === undefined){
            return <React.Fragment></React.Fragment>
        }
        let appointments = [];
        let startTime = currentAppointment.appointmentDetails.appointmentDate + ', ' + currentAppointment.appointmentDetails.slotStartTime;
        let endTime = currentAppointment.appointmentDetails.appointmentDate + ', ' + currentAppointment.appointmentDetails.slotEndTime;
         appointments.push(
             <Row  key="dualAppointmentConfirmDeatils" id="dualAppointmentConfirmDeatils" collapse expanded className="no-margin">
                        <div>
                            <div id="appointment-manager-content" className="appointment-manager-content-slots">
                                <div id="dual-appointment-manager-details-header-div" className="appointment-manager-details-header-div-telstra">
                                    <div className="appointment-manager-slots-slot-header">
                                        <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading-dual">
                                            <span className="appointment-manager-slots-slots-heading-dual">You need to reschedule your </span>                                                
                                            <span className="appointment-manager-slots-slots-heading-dual">{currentAppointment.appointmentType.charAt(0).toUpperCase()+currentAppointment.appointmentType.toLowerCase().slice(1)} technician appointment</span>
                                        </div>
                                    </div>
                                </div>    
                                <div id="dual-appointment-manager-details-type-div" className="appointment-manager-details-header-div">
                                    <div className="appointment-manager-slots-slot-header">
                                            <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading">
                                                <span className="appointment-manager-details-subheading-desktop">Appointment </span> 
                                                <span className="appointment-manager-details-subheading-desktop">{allAppointments.appointments.indexOf(currentAppointment)+1} of </span>
                                                <span className="appointment-manager-details-subheading-desktop">{allAppointments.appointments.length}</span>
                                            </div>
                                            <div id="dual-appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-desktop-light">
                                                {currentAppointment.appointmentType.charAt(0).toUpperCase()+currentAppointment.appointmentType.toLowerCase().slice(1)} technician
                                            </div>                                        
                                    </div>
                                </div>
                                <div id="dual-appt-Confirm-slot-div" className="appointment-details-date-desktop">
                                    {currentAppointment.appointmentDetails && currentAppointment.appointmentDetails.appointmentDate ? moment(currentAppointment.appointmentDetails.appointmentDate).format('dddd D MMMM') : null}<br></br>                                    
                                    {moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}
                                </div>

                                    <div id="appointment-details-banner-message-div-desktop" className="appointment-details-banner-message-div-desktop-dual">
                                    <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-details-banner-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                            </div>
                                            <div className="appointment-details-banner-message-content-column appt-date-msg-nbn-telstra">
                                                    <span id="dual-appt-eu-msg" className="appointment-details-banner-message-content-text">
                                                            {constants.RESCHEDULE_TELSTRA_APPT_MSG}
                                                        </span>
                                                </div>
                                        </TextStyle>
                                        </Surface>
                                    <div>
                                    </div>
                                    <div className="appointment-details-banner-message-info-rectangle"></div> 
                                </div>
                                <div id="dual-Confirm-Reschedule_appointment-div" className="appointment-resc-reschedule-button-div">
                                    <ActionButton element="button" variant="LowEmphasis" label="Yes, reschedule" tabIndex="0"  onClick={(event) => this.closeDualAppointment(currentAppointment, allAppointments)}/>
                                </div>
                                <div id="dual-Confirm-Reschedule_appointment-div">
                                    <ActionButton element="button" variant="LowEmphasis" label="Cancel reschedule" tabIndex="0"  onClick={() => this.closeAppointmentCompleted()}/>
                                </div>
                            </div>
                        </div>
                    </Row>
         );
         return appointments;
    }                
    
    isMobileDevice() {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if(navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {
        
        let devicelClassName = "popup-inner desktop-layout";
        if(this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if(this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }

    closeDualAppointment(currentObj, apptAPIResp) {
        analytics.addOmnitureObjectForEventClick(constants.APPT_MANDATORY_TELSTRA_RESCHEDULE_PAGE_NAME_ANALYTICS, constants.YES_RESCHEDULE_CLICK_ANALYTICS, 'linkClick');
        this.props.closeDualAppointmentConfirm(currentObj,apptAPIResp)
    }

    // showOldAppointment() {
    //     this.props.onCloseConfirmApptKeepOld();
    // }

}    
 

export default TelstraAppointmentReschedule;