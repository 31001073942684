import _ from 'lodash';
import * as client from "../adapters/client";
import * as analytics from '../analytics-utils';
import * as constants from "../presenters/Constants";
import * as utils from "../utils";

const REQUEST_APPOINTMENT_DETAILS = 'REQUEST_APPOINTMENT_DETAILS';
const RECEIVE_APPOINTMENT_DETAILS = 'RECEIVE_APPOINTMENT_DETAILS';
const RECEIVE_APPOINTMENT_DETAILS_ERROR = 'RECEIVE_APPOINTMENT_DETAILS_ERROR';
const RECEIVE_APPOINTMENT_DETAILS_TOKEN_EXPIRED = 'RECEIVE_APPOINTMENT_DETAILS_TOKEN_EXPIRED';

export function requestAppointmentDetails() {
    return {
        type: REQUEST_APPOINTMENT_DETAILS
    }
}

export function receiveAppointmentDetails(json) {
    let actionObj = {
        type: RECEIVE_APPOINTMENT_DETAILS,       
        appointmentDetails: json.data ? json.data : null,
        errors: json.errors ? json.errors : null     
        //appointmentDetails: appointmentDetails        
    }
    localStorage.setItem('orderCategory', json.data ? json.data.orderCategory : null);
    return actionObj;
}

export function receiveAppointmentDetailsError(json) {
    let errorMainMsg = constants.DEFAULT_ERROR_MAIN_MSG;
    const { errors } = json || {};
    if(errors && !utils.isEmpty(errors[0].message.message1)) {
        errorMainMsg = errors[0].message.message1;
    }
    analytics.createErrorEventObj(constants.APPT_ERROR_PAGE_NAME_ANALYTICS, 'error', 'Business', json.status, errorMainMsg);
   
    switch (json && json.status) {
        case 401:
            return {type: RECEIVE_APPOINTMENT_DETAILS_TOKEN_EXPIRED}
        case 403:
          if(utils.isAppointOrchestratorEnabled()) {
            return {
              type: RECEIVE_APPOINTMENT_DETAILS_ERROR,
              errors: utils.getUnauthorisedError(),
            }
          }
        case 500:
        case 400:
        case 404:
        case 304:
        case 422:
        case 503:
        case 504:
            let actionObj = {
                type: RECEIVE_APPOINTMENT_DETAILS_ERROR,
                errors: json.errors ? json.errors : null
            }
            return actionObj;  
        default:
            return {type: null};
    }
}

export function fetchAppointmentDetailsApi(orderId, fetcher = client.fetchAppointmentDetails) {
    return (dispatch) => {
        dispatch(requestAppointmentDetails());
        fetcher(dispatch, receiveAppointmentDetails, receiveAppointmentDetailsError, orderId);
    }
}

export function fetchAppointmentDetailsApiAuth(accessToken, orderId,  fetcher = client.fetchAppointmentDetailsAuth) {
    return (dispatch) => {
        dispatch(requestAppointmentDetails());
        fetcher(dispatch, receiveAppointmentDetails, receiveAppointmentDetailsError, accessToken, orderId);
    }
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case REQUEST_APPOINTMENT_DETAILS:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                errors: action.errors
            });
        case RECEIVE_APPOINTMENT_DETAILS:
            // https://jira.tools.telstra.com/browse/OSTK-2624
            // Filter out cancelled appointments
            const apptDetails = _.assign({}, action.appointmentDetails, {
                appointments: _.filter(action.appointmentDetails.appointments, appt => {
                    return appt.appointmentStatus !== 'Cancelled'
                })
            })
            return Object.assign({}, state, {
                isFetching: false,
                appointmentDetails: apptDetails,
                errors: action.errors
            });
        case RECEIVE_APPOINTMENT_DETAILS_TOKEN_EXPIRED:
            return Object.assign({}, state, {
                isFetching: false,
                isExpired: true,
                isUnauthorized: false,
                errors: action.errors
            });
        case RECEIVE_APPOINTMENT_DETAILS_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                errors: action.errors
            });       
        default:
            return state
    }
}
