import * as analytics from './analytics-utils';

let events = [];
export function getDefaultDataLayerObject() {

    let defaultObj = {
        "page": {},
        "event": [],
        "application": [],
        "version": '1.0' // W3C Digital Data Layer standard, fixed value
    }
    return defaultObj;
};

export const isValidDataLayerObject = inputObj => {
    // check object type
    if (!inputObj || typeof inputObj !== 'object') {
        return false;
    }

    // check mandatory properties
    if (!inputObj.page || typeof inputObj.page !== 'object') {
        return false;
    } else if (!inputObj.event || !Array.isArray(inputObj.event)) {
        return false;
    } else if (!inputObj.version || typeof inputObj.version !== 'string') {
        return false;
    }

    return true;
};

export function createPageObj(pageName) {
    let pageObj = null;
    let primaryCategory = "Appointment-Manager";
    let apptType = sessionStorage.getItem('apptType');
    
    pageObj = {
        "pageInfo": {
            "pageName": pageName,
            "referringURL": document.referrer,
            "sysEnv": window.env.REACT_APP_OMNITURE_DEPLOY_ENV,
            "deployEnv": window.env.REACT_APP_OMNITURE_DEPLOY_ENV
        },
        "category": {
            "primaryCategory": primaryCategory,
            "secondaryCategory": apptType,
            "pageType": "Service"
        },
        "attributes": {
            "division": "TD",
            "subDivision": "TR",
            "subDivisionUnit": "TR"
        }
    }
    return pageObj;
}

export function createErrorObj(errorObj) {
    let pageErrorObj = null;
    
    pageErrorObj = errorObj;
    return pageErrorObj;
}

export function createEventObj(eventAction, eventCategory) {
    let eventObj = null;
    eventObj = {
        "eventInfo":{
            "eventAction":"click",
            "eventType":"clickTrack",
            "eventCategory": eventCategory,
            "eventName":eventAction
        },
        "attributes":{}
    }
    return eventObj;
}

export function addOmnitureObjectForEventClick(pageName, eventAction, eventCategory) {
    let pageObj = null;
    let eventObj = null;
    let applicationObj = null;

    pageObj = analytics.createPageObj(pageName);
    if(eventAction) 
        eventObj = analytics.createEventObj(eventAction, eventCategory);
    
    analytics.createDateLayerObj(pageObj, eventObj); 
}

export function createApplicationObj(orderDetails, accessToken) {
    let applicationObj = {
        "orderTracker":{}
    }

    let orderCategory = orderDetails && orderDetails.orderCategory ? orderDetails.orderCategory : null;
    let technologyType = orderDetails && orderDetails.technologyType ? orderDetails.technologyType : null;
    let isAuthenticated = accessToken ? true : false;
    let orderStatus = orderDetails && orderDetails.orderStatus ? orderDetails.orderStatus : null;

    applicationObj.orderTracker = {
        "orderCategory": orderCategory,
        "orderStatus": orderStatus,
        "isAuthenticated": isAuthenticated
    }   

    applicationObj.orderTracker.techType = technologyType
    return applicationObj;
}

export function createDateLayerObj(pageObj, eventObj = null, errorObj = null) {
    let dataLayerObj = {};
   
    dataLayerObj.page = pageObj;

    if(Array.isArray(events) && eventObj) {
        events.push(eventObj);
    }
    events = events.filter(function (el) {
        return el != null;
      });
    dataLayerObj.event = events;
    
    // set default data layer if not already have one
     window.digitalData = window.digitalData || getDefaultDataLayerObject();

    // merge and update if valid
     const updatedDataLayerObj = Object.assign(window.digitalData, dataLayerObj);
    if (isValidDataLayerObject(updatedDataLayerObj)) {
        window.digitalData = updatedDataLayerObj;
    }

}

export function createErrorEventObj(pageName, eventAction, eventType, eventCategory = null , eventName = null) {
    let pageObj = null;
    let eventObj = null;
    pageObj = analytics.createPageObj(pageName);  
    eventObj = {
        "eventInfo":{
            "eventAction": eventAction,
            "eventType": eventType,
            "eventCategory":eventCategory,
            "eventName":eventName
        },
        "attributes":{}
    }
    analytics.createDateLayerObj(pageObj, eventObj);
}
