import {browserHistory} from 'react-router';
import React from 'react';
import * as constants from './presenters/Constants';

export function redirect(e, url) {
    if(e)e.preventDefault();
    window.location = url
}

export function openInNewTab(e, url) {
    if(e)e.preventDefault();
    window.open(url,'_blank')
}

export function openInSameTab(e, url) {
    if (e.ctrlKey){
        window.open(url,'_blank')
      }
    else{
            if(e)e.preventDefault();
            window.open(url,'_self')
      }
}

export function route(e, url) {
    if(e)e.preventDefault();
    browserHistory.push(url)
}

export function extractDayWithSuffix(dateStr){
    if (!dateStr){
        return "";
    }
    let day = dateStr.split(' ',2)[1];
    let  j = day % 10,
        k = day % 100;
    if (j === 1 && k !== 11) {
        return day + "st";
    }
    if (j === 2 && k !== 12) {
        return day + "nd";
    }
    if (j === 3 && k !== 13) {
        return day + "rd";
    }
    return day + "th";
}

export function extractMonthYear(dateStr){
    if (!dateStr){
        return "";
    }    
    let dateComps = dateStr.split(' ');
    return dateComps[2] + " " + dateComps[3];
}

export function isLocalStorageEnabled(){
    try {
        var mod = '__storage_test__';
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch(e) {
        return false;
    }
}

export function convertToTimestamp(date){
    return date.toISOString();
}

export function convertDateToAppointmentAPITimestamp(date){
    return date.toISOString().substring(0, 19);
}



 export function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function isMobileDevice() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    }
}

export function isIpadDevice() {
    if (navigator.userAgent.match(/iPad/i)) {
        return true;
    }
}

export function isValidUrl(testUrl) {
    if (testUrl === null || testUrl === undefined || testUrl === '') {
        return false;
    }
    let url;
    try {
        url = new URL(testUrl);
    } catch (e) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
}

export function replaceMessageTextWithUrl(message, url) {
    let formattedMessage = [];
    if (message && message.includes('<message us>')) {
        let messageArray = message.split('<message us>');
        formattedMessage.push(messageArray[0]);
        if(isMobileDevice()){
            formattedMessage.push(<a id="createdMessage2" key="createdMessage2" className="appointment-error-secondary-msg-heading-mobile-hyperlink" href={url} onClick={(e) => openInNewTab(e, url)}>message us</a>);
        } else {
            formattedMessage.push(<a id="createdMessage2" key="createdMessage2" className="appointment-error-secondary-msg-heading-desktop-hyperlink" onClick={() => openChat()}>message us</a>);
        }
        formattedMessage.push(messageArray[1]);
    } else {
        formattedMessage = message;
    }
    return formattedMessage;
}

export function getUnauthorisedError() {
  return [{
      "code": 403,
      "message": {
        "message1": constants.APPPOINMENT_RESCHEDULE_UNAUTHRISED_ERROR_MSG_1,
        "message2": constants.APPPOINMENT_RESCHEDULE_UNAUTHRISED_ERROR_MSG_2,
        "message3": constants.APPOINTMENT_MANAGER_ERROR_PAGE_BUTTON
      }
    }]
}

export function isEmpty(value) {
  let valueIsEmpty = value === undefined || value === null || value === '';
  if (!valueIsEmpty && typeof value.trim === 'function') valueIsEmpty = value.trim() === '';
  return valueIsEmpty;
}

export function isAppointOrchestratorEnabled() {
  return window.env.REACT_APP_IS_APPOINT_ORCHESTRATOR_ENABLED === "true";
}

export function openChat() {
    const planName = localStorage.getItem('planName');
    const orderId = localStorage.getItem('orderId');
    const linkText = "message us";
    const chatGroupTags = ["telstra", "dtq", "gbs", "nbn", "activation", "ost"];
    const chatParams = {
        isDirect: true,
        section: chatGroupTags,
    };
    const data = {
        type: 'error',
        error: {
            contextId: planName,
            message:  `
                    Customer Message : ${linkText} <br>
                    Order ID: ${orderId}`
        }
    }
    
    window.livePerson.show(chatParams);
    window.lpTag.sdes.push(data);
}
