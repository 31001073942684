import React from "react";
import {Column, Row} from "react-foundation-components/lib/global/grid";
import AppoinmentRescheduleError from "./AppoinmentRescheduleError";
import Calendar from 'react-calendar/dist/entry.nostyle';
import * as utils from "../utils";
import * as constants from "./Constants";
import Spinner from "../Spinner/Spinner";
import moment from "moment";
import alertIcon from "../images/Alert Small@1x.svg";
import * as analytics from "../analytics-utils";
import { ActionButton, Surface, TextStyle } from '@able/react';

class AppointmentRescheduleAvailableSlots extends React.Component {
    constructor(props) {
        super(props);
        this.isMobileDevice = this.isMobileDevice.bind(this);
        this.isIpadDevice = this.isIpadDevice.bind(this);
        this.getClassNameForDevice = this.getClassNameForDevice.bind(this);
        let slotInputReq = this.props.slotAPIRequest;
        this.calendarDate = null;
        this.earliestRescheduleDate = null;
        this.state = {
            dateClicked: new Date(), dateChanged: false, appointmentId: slotInputReq.appointmentId,
            appointmentType: slotInputReq.appointmentType, euRequired: slotInputReq.euRequired, showCalendar: false, showReschedulePopup: false, showMore: false, showMoreHidden: false
        }
        this.hardwareDeliveryDate = this.props.hardwareDeliveryDate;
        this.nbnAppointmentDate = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDate ? this.props.appointmentDetails.appointmentDate : null;
        this.accessToken = this.props.accessToken && this.props.accessToken !== undefined ? this.props.accessToken : null;
        this.appointmentAddressState = null;
        this.orderSystem = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.orderSystem : null;
        this.evaluationID = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.evaluationID : null;
        this.portInFNN = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.portInFNN : null;
        if(this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails
            && this.props.appointmentDetails.appointmentDetails.appointments
            && this.props.appointmentDetails.appointmentDetails.appointments[0]
            && this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentAddress
            && this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentAddress.state ) {
            this.appointmentAddressState = this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentAddress.state;
        }
        this.days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.appointment = slotInputReq.appointment ? slotInputReq.appointment : null;
        this.transactionReferenceID = slotInputReq.transactionReferenceID ? slotInputReq.transactionReferenceID : null;
        if(this.accessToken) {
            this.props.fetchAppointmentSlotsApiAuth(this.accessToken, slotInputReq.appointmentType, slotInputReq.appointmentId, this.props.isoDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);	
        } else {	
            this.props.fetchAppointmentSlotsApi(slotInputReq.appointmentType, slotInputReq.appointmentId, this.props.isoDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate,this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);	
        }

    }

    render() {
        let spinnerMessage = '';
        if((this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails) && (this.props.appointmentDetails.appointmentDetails.appointments.length > 1 || this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentType === 'TELSTRA')) {
            spinnerMessage = constants.APPT_WAIT_MSG;
        }
        let slotInputReq = this.props.slotAPIRequest;
        let apptAPIResp = this.props.appointmentSlots && this.props.appointmentSlots.appointmentSlots ? this.props.appointmentSlots.appointmentSlots : null;

        //for nbn 404
       // const apptAPIResp = { "status": 404, "code": 400400, "responseCode": null, "time": "2021-02-16T04:20:52.114+0000", "correlationId": "732c5ecc-2669-4cbc-9398-25fea8e6bc12", "path": "/v1/appointment-manager-bff/fetch-slots", "method": "POST", "data": {}, "request": { "params": { "orderCategory": "Fixed" }, "method": "POST", "path": "/v1/appointment-manager-bff/fetch-slots" }, "errors": [{ "message": { "message1": "There are no appointments available on your chosen date. Please try another date.", "message2": null, "message3": "Go back" }, "code": "400400" }] }

        //FOR NBN appt
        // const apptAPIResp = {
        //     "data": {
        //         "appointmentType": "Telstra",
        //         "earliestRescheduleDate": "2019-11-30",
        //         "latestRescheduleDate": "2019-01-29",
        //         "slots": [
        //             {
        //                 "id": "0d71d9ac-63b2-413c-8589-bb811818fc13-2",
        //                 "date": "2019-11-30",
        //                 "slotStartTime": "8 AM",
        //                 "slotEndTime": "12 PM"
        //             },
        //             {
        //                 "id": "0d71d9ac-63b2-413c-8589-bb811818fc13-3",
        //                 "date": "2019-11-30",
        //                 "slotStartTime": "12 PM",
        //                 "slotEndTime": "3 PM"
        //             },
        //             {
        //                 "id": "0d71d9ac-63b2-413c-8589-bb811818fc13-4",
        //                 "date": "2019-12-01",
        //                 "slotStartTime": "8 AM",
        //                 "slotEndTime": "12 PM"
        //             },
        //             {
        //                 "id": "0d71d9ac-63b2-413c-8589-bb811818fc13-5",
        //                 "date": "2019-12-02",
        //                 "slotStartTime": "3 PM",
        //                 "slotEndTime": "7 PM"
        //             }
        //         ]
        //     }
        // }

        //FOR Telstra appt
        // const apptAPIResp = {
        //         "appointmentType": "Telstra",
        //         "earliestRescheduleDate": "2019-11-30",
        //         "latestRescheduleDate": "2019-01-29",
        //         "slots": [
        //             {
        //             "date": "Wednesday, 18 March 2020",
        //                 "slotStartTime": "8:00 AM",
        //                 "slotEndTime": "12:00 PM"
        //             },
        //             {
        //                 "date": "Wednesday, 18 March 2020",
        //                 "slotStartTime": "1:00 PM",
        //                 "slotEndTime": "5:00 PM"
        //             },
        //             {
        //                 "date": "Thursday, 19 March 2020",
        //                 "slotStartTime": "8:00 AM",
        //                 "slotEndTime": "12:00 PM"
        //             },
        //             {
        //                 "date": "Thursday, 19 March 2020",
        //                 "slotStartTime": "1:00 PM",
        //                 "slotEndTime": "5:00 PM"
        //             },
        //             {
        //                 "date": "Friday, 20 March 2020",
        //                 "slotStartTime": "8:00 AM",
        //                 "slotEndTime": "12:00 PM"
        //             },
        //             {
        //                 "date": "Friday, 20 March 2020",
        //                 "slotStartTime": "1:00 PM",
        //                 "slotEndTime": "5:00 PM"
        //             }
        //         ]
        //     };


        const { appointmentSlots } = this.props;
        if (appointmentSlots.isFetching) {
            return <div>
                <Row collapse expanded>
                    <Column large={2}>
                    </Column>
                </Row>
                <Row collapse expanded>
                    <Column largeCentered="centered" large={8}>
                        <div className="telstra-spinner-box">
                            <div>
                                <Spinner size="large" message={spinnerMessage} />
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
        }
        else {
        return <div>
            {appointmentSlots && !appointmentSlots.isError && apptAPIResp && apptAPIResp.slots ? this.renderAppointmentsData(apptAPIResp, slotInputReq) : <AppoinmentRescheduleError dateChanged={this.state.dateChanged} displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.orderSystem} closeApptErrorAndResetDate={this.closeApptErrorAndResetDate.bind(this)} closeApptErrorAndChangeAppt={this.props.closeApptErrorAndChangeAppt} openAppointmentDetailsPopup={this.props.openAppointmentDetailsPopup} errors={appointmentSlots.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />}
        </div>
        }
    }

    renderAppointmentsData(apptAPIResp, slotInputReq) {
        const pageName = slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_NBN ? constants.APPT_AVLBL_NBN_SLOTS_PAGE_NAME_ANALYTICS : constants.APPT_AVLBL_TELSTRA_SLOTS_PAGE_NAME_ANALYTICS;
        analytics.addOmnitureObjectForEventClick(pageName);
        let allAppointments = this.props.allAppointments;
        let lastAppointmentSlot = apptAPIResp.slots[apptAPIResp.slots.length - 1].date;
        let date = new Date(Date.parse(apptAPIResp.slots[0].date));
        let searchEndDate = slotInputReq && slotInputReq.appointment && slotInputReq.appointment.searchEndDate;
        let futureDate = searchEndDate ? new Date(searchEndDate) : new Date(moment().add(3,'months').calendar());

        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        this.calendarDate = date.getDate() + " " + this.month[date.getMonth()];

        let todayDate = new Date(moment());
        let day = todayDate.getDate() + " " + this.month[todayDate.getMonth()];
        let {dateClicked}= this.state;
        let selectedDate = dateClicked.getDate()+ " " + this.month[dateClicked.getMonth()];


        if(apptAPIResp && apptAPIResp.earliestRescheduleDate) {
            this.earliestRescheduleDate = new Date(Date.parse(apptAPIResp.earliestRescheduleDate));
        } else if( this.props.isoDate) {
            //set earliestRescheduleDate for APLAR response
            this.earliestRescheduleDate = new Date(Date.parse(this.props.isoDate));
        }

        return <Row collapse expanded className="no-margin">
            <div id="appointment-manager-content" className="confirm-appt-desktop" onClick={this.hideCalendar.bind(this)}>
                <Row id="appointmentDetails" collapse expanded >
                    <div className="appt-confirm-content appt-confirm-content-desktop">
                        {allAppointments && allAppointments.appointments && allAppointments.appointments.length > 1 ?
                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading-desktop">
                                <span className="appointment-manager-slots-slots-heading-desktop">Appointment </span>
                                {slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_NBN?
                                    <span className="appointment-manager-slots-slots-heading-desktop">1 of </span>
                                    : <span className="appointment-manager-slots-slots-heading-desktop">2 of </span>
                                }
                                <span className="appointment-manager-slots-slots-heading-desktop">{allAppointments.appointments.length}</span>
                            </div>
                            :
                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-slots-heading-desktop">
                                Appointment
                        </div>
                        }
                        <div className="appt-slot-text-container">
                            {slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                                <span className="appointment-manager-slots-slots-sub-heading-desktop">{slotInputReq.appointmentType.charAt(0).toUpperCase()+slotInputReq.appointmentType.toLowerCase().slice(1)} technician</span>
                                :<span className="appointment-manager-slots-slots-sub-heading-desktop">{slotInputReq.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}</span>
                            }

                        </div>
                    </div>
                    <div id="appointment-manager-content" className="non-eu-msg-container-desktop-slots">
                        <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-details-banner-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                            </div>
                                            <div className="appointment-details-banner-message-content-column">
                                                    {slotInputReq.euRequired  || (slotInputReq.appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) ?
                                        <span className="appointment-details-banner-message-content-text">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                        :
                                        <span className="appointment-details-banner-message-content-text">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                    }
                                                </div>
                                        </TextStyle>
                                        </Surface>
                        <div className="appointment-details-banner-message-info-rectangle"></div>
                    </div>
                    <div id="available-Appointment-Calendar-message" className="available-Appointment-Calendar-container">
                        <span className="confirm-appt-text-slots">Available appointments from </span><br></br>
                        <span className="confirm-appt-text-slots">{this.state.dateChanged? selectedDate : day}</span>
                        <div className="change-btn-div-slots" id='change-btn-div-slot'>
                            <ActionButton element="button" variant="LowEmphasis" label="Change date" tabIndex="0"  onClick={this.showCalendar.bind(this)}/>
                        </div>
                        {this.state.showCalendar ?
                            <div id="calendar-widget" className="calendar-widget"

                            >

                                <Calendar onChange={this.onChange} minDate={this.earliestRescheduleDate}
                                    tileDisabled={({ date, view }) => (date.getDay() === 6 || date.getDay() === 0)}
                                    value={this.state.date} next2Label={null} prev2Label={null}
                                    maxDate={futureDate}
                                />
                            </div>
                            : null
                        }
                    </div>

                    {this.renderAppointmentSlot(apptAPIResp, apptAPIResp.slots.length, allAppointments)}

                </Row >
            </div>
        </Row>
    }

    closeApptErrorAndResetDate(){
        this.setState({
            dateClicked: new Date()
        });
    }


    renderAppointmentSlot(apptAPIResp, slotsCounter, allAppointments) {
        let lastAppointmentSlot = apptAPIResp.slots[apptAPIResp.slots.length - 1].date;
        let appointmentSlots = [];
        let showMore = this.state.showMore;
        let slotsNum = showMore ? slotsCounter : 3;
        let timezone = apptAPIResp.timeZone ? apptAPIResp.timeZone : null;
        let appointmentType = apptAPIResp.appointmentType;

        if (apptAPIResp.slots.length < 3) {
            slotsNum = apptAPIResp.slots.length;
        }


        for (let counter = 0; counter < slotsNum; counter++) {

            let appointmentSlotCurrent = apptAPIResp.slots[counter];
            let date = new Date(Date.parse(appointmentSlotCurrent.date));
            let calendarDate = this.days[date.getDay()] + " " + date.getDate() + " " + this.month[date.getMonth()];
            let startTime = appointmentSlotCurrent.date + ', ' + appointmentSlotCurrent.slotStartTime;
            let endTime = appointmentSlotCurrent.date + ', ' + appointmentSlotCurrent.slotEndTime;
            appointmentSlots.push(<div key={"appointment-slot-" + counter} id="apppointment-slot" className="appointment-manager-slot-list" onClick={(e) => this.reschedule(e, appointmentSlotCurrent, timezone, appointmentType, allAppointments)}>
                <div>
                    <div id="appointment-manager-slot-list-content">
                        <div className="appointment-manager-slot-list-content-row">
                            <div className="appointment-manager-slot-list-content-column1">
                                <div className="appt-date-container">
                                    <span className="appointment-manager-slot-list-text-date-desktop">{calendarDate}</span>
                                    <br />
                                </div>
                                <span className="appointment-manager-slot-list-text-time-desktop font-time-subtitle">{moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}</span>
                            </div>
                            <div className="appointment-manager-slot-list-content-column2">
                                {">"}
                            </div>
                        </div>
                        <hr className="appointment-manager-divider" />
                    </div>
                </div>
            </div>);
        }

        appointmentSlots.push(<div key={"appointment-show-more"} id="appointment-show-more" className="appointment-show-more" >

            {this.state.showMore == true ?
                <a id="showMore" className="progress-link how-to-prepare" onClick={(e) => this.searchAppt(lastAppointmentSlot)}>
                    <div>
                        <span id="showMoreButton" className="show-more-button-desktop"  >See more +</span>

                    </div>
                </a>
                :
                <a id="showMore" className="progress-link how-to-prepare" onClick={() => this.setState({ showMore: true })}>
                    <div>
                        <span id="showMoreButton" className="show-more-button-desktop" > See more + </span>

                    </div>
                </a>

            }


        </div>);

        return <div className="appointment-manager-slot-list-content-desktop slot-Container">

            <hr className="appointment-manager-divider-slot" />
            {appointmentSlots}
            {/* <hr className="appointment-manager-divider-slot" /> */}
        </div>;
    }

    showCalendar() {
        this.setState({
            showCalendar: !this.state.showCalendar
        });
    }

    hideCalendar(e) {
        let elementClassName = e.target.className;
        let targetElement = e.target;

        //on click inside the calendar, do not propogate the event
        if (elementClassName.startsWith('react-calendar') || targetElement.matches('abbr')) {
            e.stopPropagation();
        } else {
            if (this.state.showCalendar) {
                this.setState({
                    showCalendar: false
                });
            }
        }
    }

    onChange = date => this.setState({ dateClicked: date, dateChanged: true, showCalendar: !this.state.showCalendar })

    componentDidUpdate(prevProps, prevState) {
        let slotInputReq = this.props.slotAPIRequest;
        if (prevState.dateClicked !== this.state.dateClicked) {
            let fromDate = utils.convertDateToAppointmentAPITimestamp(this.state.dateClicked)
            this.appointment.searchStartDate = fromDate;
            if(this.accessToken) {
                this.props.fetchAppointmentSlotsApiAuth(this.accessToken, slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
            } else {
                this.props.fetchAppointmentSlotsApi(slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
            }
        }
    }

    reschedule(e, appointmentSlotCurrent, timezone, appointmentType, allAppointments) {
        let slotInputReq = this.props.slotAPIRequest;

        let selectedSlot = {appointmentId: slotInputReq.appointmentId, appointmentType:slotInputReq.appointmentType, appointmentDate: appointmentSlotCurrent.date, slotStartTime: appointmentSlotCurrent.slotStartTime, slotEndTime: appointmentSlotCurrent.slotEndTime, id:appointmentSlotCurrent.id, euRequired:slotInputReq.euRequired, deliveryType:slotInputReq.deliveryType, timezone: timezone, dependentAppointments: appointmentSlotCurrent.dependentAppointments}
        if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN && allAppointments && allAppointments.appointments && allAppointments.appointments.length > 1) {
            this.props.dualApptConfirm(allAppointments.appointments[0], allAppointments, constants.APPT_CATEGORY_TELSTRA, selectedSlot)
        } else {
            this.props.onCloseSlots(selectedSlot, allAppointments);
        }
        this.setState({ showReschedulePopup: !this.state.showReschedulePopup });
    }

    searchAppt(appointmentDate) {
        var date = new Date(appointmentDate);
        let slotInputReq = this.props.slotAPIRequest;
        let fromDate = utils.convertDateToAppointmentAPITimestamp(date);
        this.appointment.searchStartDate = fromDate;
        if(this.accessToken) {
            this.props.fetchAppointmentSlotsApiAuth(this.accessToken, slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
        } else {
            this.props.fetchAppointmentSlotsApi(slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
        }
    }

    isMobileDevice() {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if (navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {

        let devicelClassName = "popup-inner desktop-layout";
        if (this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if (this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }
}
export default AppointmentRescheduleAvailableSlots;
