import React, { Component } from "react";
import queryString from "query-string";
import moment from "moment";

import { Column, Row } from "react-foundation-components/lib/global/grid";
import Spinner from "../Spinner/Spinner";
import * as utils from "../utils";
import telstraLogo from "../images/telstra-logo.svg";
import * as constants from "./Constants";
import AppointmentDetails from "./AppointmentDetails";
import AppointmentRescheduleAvailableSlots from "./AppointmentRescheduleAvailableSlots";
import AppointmentConfirm from "./AppointmentConfirm";
import AppointmentRescheduleCompleted from "./AppointmentRescheduleCompleted.js";

import AppointmentDetailsMobile from "./AppointmentDetailsMobile";
import AppointmentRescheduleCompletedMobileView from "./AppointmentRescheduleCompletedMobileView.js";
import AppointmentRescheduleAvailableSlotsMobile from "./AppointmentRescheduleAvailableSlotsMobile";
import AppointmentRescheduleConfirmMobile from "./AppointmentRescheduleConfirmMobile";
import DualAppointmentConfirmMobile from "./DualAppointmentConfirmMobile";
import TelstraAppointmentRescheduleMobile from "./TelstraAppointmentRescheduleMobile";
import TelstraAppointmentReschedule from "./TelstraAppointmentReschedule";
import DualAppointmentConfirm from "./DualAppointmentConfirm";
import NoAppointmentRescheduled from "./NoAppointmentRescheduled";
import NoAppointmentRescheduledMobile from "./NoAppointmentRescheduledMobile";
import { caimanLogin, caimanLoginForSafari, getAccessToken } from "../adapters/caiman-auth";
import {APPT_CATEGORY_NBN} from "./Constants";


class Appointments extends Component {

    constructor(props) {
        super(props);
        const { user } = this.props;
        this.timer = null;

        this.firstTime = true;
        this.urlParams = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;
        this.hashParam = this.props.location && this.props.location.hash ? this.props.location.hash : null;
        this.appointmentType = null;
        this.accessToken = null;
        this.diffDaysNbnTelstra = null;
        this.hardwareDeliveryDate = this.props.hardwareDeliveryDate;
        this.dualApptsArr = [];
        this.selectedNbnAppt = null;
        this.telstraOldSlot = null;
        this.noApptChangeForDual = false;
        this.dependentTelstraEarliestRescheduleDate = null;
        this.displayCompleteCTA = localStorage.getItem('displayCompleteCTA');
        this.promptNone = localStorage.getItem('promptNone');
        this.orderId = localStorage.getItem('orderId');
        this.callBackUrl = localStorage.getItem('callbackURL');        

        if (this.hardwareDeliveryDate === undefined) {
            this.hardwareDeliveryDate = localStorage.getItem('hardwareDeliveryDate');
        }

        if (this.hardwareDeliveryDate === "null") {
            this.hardwareDeliveryDate = null;
        }

        if (window.env.REACT_APP_DEV_ENV === "true") {
            if(this.urlParams != null || this.urlParams != undefined) {
                this.orderId = this.urlParams.orderId;
                localStorage.setItem('orderId', this.urlParams.orderId);
                localStorage.setItem('callbackURL', this.urlParams.callbackURL);
                localStorage.setItem('displayCompleteCTA', this.urlParams.displayCompleteCTA);
                localStorage.setItem('promptNone', this.urlParams.prompt);
                localStorage.setItem('planName', this.urlParams.planName);
            }
            this.props.fetchAppointmentDetailsApi(this.orderId);
        } else {               
            if (this.urlParams === null) {
                localStorage.removeItem('orderId');
                localStorage.removeItem('callbackURL');
                localStorage.removeItem('displayCompleteCTA');
                localStorage.removeItem('promptNone');
                localStorage.removeItem('planName', this.urlParams.planName);
                this.handleWithOrWithoutSafariLogin();
            } else if (this.urlParams.code === undefined) {
                localStorage.setItem('orderId', this.urlParams.orderId);
                localStorage.setItem('callbackURL', this.urlParams.callbackURL);
                localStorage.setItem('displayCompleteCTA', this.urlParams.displayCompleteCTA);
                localStorage.setItem('promptNone', this.urlParams.prompt);
                localStorage.setItem('planName', this.urlParams.planName);
                this.promptNone = localStorage.getItem('promptNone');
                
                if(this.urlParams && (this.urlParams.prompt === null || this.urlParams.prompt === undefined)) {
                    this.promptNone = null;
                    localStorage.removeItem('promptNone');
                }
                this.handleWithOrWithoutSafariLogin();
            } else {                
                let code = this.urlParams.code;
                getAccessToken(code).then(authToken => {
                    if (authToken) {
                        this.accessToken = authToken.access_token;
                        this.props.fetchAppointmentDetailsApiAuth(authToken.access_token, this.orderId);
                    }
                });
            }
        }
        

        this.state = {
            apptType: null, slotInputReq: null, deliveryType: null, selectedAppointment: null, showDetailsPopup: true, showSlotsPopup: false, showApptConfirmPopup: false, showApptSuccessPopup: false, showNoApptRescheduled: false
        }

        this.tle_error = {
            "errors": [
                {
                    "message": {
                        "message1": "The Telstra technician appointment time chosen is no longer available.",
                        "message2": "You must confirm your appointment within 5 minutes. Please choose another date and time.",
                        "message3": "View available appointments"
                    },
                    "code": "600600"
                }
            ]
        };
    }

    handleWithOrWithoutSafariLogin() {
        if(this.promptNone !== null && this.promptNone !== undefined && this.promptNone !== "null" && this.promptNone !== "undefined") {
            caimanLoginForSafari();
        } else {
            caimanLogin();
        }
    }

    render() {
        return (
            <div>
                <div className="account-info"></div>
                {this.renderAppointmentsFlow()}
            </div>
        );
    }

    renderAppointmentsFlow() {
        return (
            <div>
                <div>
                    <Row collapse expanded>
                        <Column>
                            <div className="app-content">
                                <Row collapse className="header-box">
                                    <Column className="flex-row">
                                        <img id="Telstra-Logo" className="telstra-logo-appointment" src={telstraLogo} alt="Telstra Logo" />
                                        <span id="order-Tracker-Header" className="header-label">Appointment manager</span>
                                    </Column>
                                   </Row>
                            </div>
                        </Column>
                    </Row>
                    <hr className="appointment-manager-divider" />
                </div>

                {this.state.showDetailsPopup ? this.renderAppointmentPopup() : ''}
                {this.state.showDualApptConfirm ? this.renderDualAppointmentConfirmPopup() : ''}
                {this.state.showSlotsPopup ? this.renderAppointmentSlotsPopup() : ''}
                {this.state.showApptConfirmPopup ? this.renderAppointmenConfirmPopup() : ''}
                {this.state.showApptSuccessPopup ? this.renderAppointmenSuccessPopup() : ''}
                {this.state.showNoApptRescheduled ? this.renderNoAppointmentRescheduled() : ''}
            </div>
        )
    }

    renderAppointmentPopup() {
        const { appointmentDetails } = this.props;
        if (appointmentDetails.isFetching === false) {
            const { appointmentDetails } = this.props;
            if (utils.isMobileDevice() || utils.isIpadDevice()) {
                return <AppointmentDetailsMobile displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} appointmentDetails={appointmentDetails} fetchAppointmentDetailsApi={this.props.fetchAppointmentDetailsApi} fetchAppointmentDetailsApiAuth={this.props.fetchAppointmentDetailsApiAuth} onCloseDetailsMobile={this.closeApptDetailsPopup.bind(this)} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} dualApptConfirm={this.dualApptConfirm.bind(this)} />
            } else {
                return <div className="appointment-reschedule-margin">
                    <Row collapse expanded className="no-margin">
                        <AppointmentDetails displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} appointmentDetails={appointmentDetails} appointmentType={this.appointmentType} nbnAppointmentId={this.nbnAppointmentId} onCloseDetails={this.closeApptDetailsPopup.bind(this)} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} dualApptConfirm={this.dualApptConfirm.bind(this)} />
                    </Row>
                </div>
            }

        } else {
            return (<div>
                <Row collapse expanded>
                    <Column large={2}>
                    </Column>
                </Row>
                <Row collapse expanded>
                    <Column largeCentered="centered" large={8}>
                        <div className="telstra-spinner-box">
                            <div>
                                <Spinner size="large" />
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
            )
        }
    }

    closeApptDetailsPopup(e) {
        let slotInputReq = this.createInputRequestForSlots(e);

        let deliveryType = e.characteristics && e.characteristics.deliveryType ? e.characteristics.deliveryType : null;
        this.setState({
            slotInputReq: slotInputReq, deliveryType: deliveryType, showDetailsPopup: false, showSlotsPopup: true
        });
    }

    dualApptConfirm(e, allAppointments, apptType, selectedSlot = null) {
        let currentAppointment = e;
        this.selectedNbnAppt = selectedSlot;
        if (selectedSlot && currentAppointment && currentAppointment.appointmentDetails
            && selectedSlot.appointmentDate && currentAppointment.appointmentDetails.appointmentDate
            && (selectedSlot.appointmentDate !== currentAppointment.appointmentDetails.appointmentDate ||
                (selectedSlot.appointmentDate === currentAppointment.appointmentDetails.appointmentDate && selectedSlot.slotStartTime !== currentAppointment.appointmentDetails.slotStartTime))) {
            this.createDualAppointmentsArrayForReschedule(currentAppointment, selectedSlot);

        }
        //get dependent appointment earliestRescheduleDate
        if(allAppointments.appointments.length > 1 && currentAppointment && currentAppointment.appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN && selectedSlot) {
            let dependentAppointmentArray = selectedSlot.dependentAppointments.filter(appt => appt.category.toLowerCase() === constants.APPT_CATEGORY_TELSTRA);
            if(dependentAppointmentArray.length > 0) {
                this.dependentTelstraEarliestRescheduleDate = dependentAppointmentArray[0].earliestReschedule;
            } else {
                this.dependentTelstraEarliestRescheduleDate = null;
            }
        }
        if (apptType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA && currentAppointment &&
            currentAppointment.appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) { //when even telstra appt is not changed
            if(this.dualApptsArr.length === 0) {
                this.setState({
                    currentAppointment: currentAppointment,
                    apptType: apptType,
                    allAppointments: allAppointments,
                    showDetailsPopup: false,
                    showSlotsPopup: false,
                    showDualApptConfirm: false,
                    showNoApptRescheduled: true
                });
            } else {
                //Clear any existing Telstra appt; before setting the unchangedTelstraAppointment
                this.dualApptsArr = this.dualApptsArr.filter(appt => appt.appointmentType.toLowerCase() != constants.APPT_CATEGORY_TELSTRA);

                let unchangedTelstraAppointment = allAppointments.appointments.filter(obj => obj.appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA)[0];
                let unchangedTelstraAppointmentDisplay = {
                    "appointmentId": unchangedTelstraAppointment.appointmentId,
                    "appointmentType": unchangedTelstraAppointment.appointmentType,
                    "evaluationID":allAppointments.evaluationID,
                    "orderSystem":allAppointments.orderSystem,
                    "euRequired": unchangedTelstraAppointment.characteristics.euRequired,
                    "transactionReferenceID": unchangedTelstraAppointment.transactionReferenceID,
                    "orderId": unchangedTelstraAppointment.orderId,
                    "appointmentSlot": {
                        "id": null,
                        "date": unchangedTelstraAppointment.appointmentDetails.appointmentDate,
                        "slotStartTime": unchangedTelstraAppointment.appointmentDetails.slotStartTime,
                        "slotEndTime": unchangedTelstraAppointment.appointmentDetails.slotEndTime,
                        "slotType": (unchangedTelstraAppointment.appointmentDetails.slotStartTime).split(" ").pop(),
                        "timeZone": unchangedTelstraAppointment.appointmentDetails.timezone
                    },
                    "currentAppointmentSlot": {
                        "date": unchangedTelstraAppointment.appointmentDetails.appointmentDate,
                        "slotStartTime": unchangedTelstraAppointment.appointmentDetails.slotStartTime,
                        "slotEndTime": unchangedTelstraAppointment.appointmentDetails.slotEndTime
                    }
                };
                this.dualApptsArr.push(unchangedTelstraAppointmentDisplay);
                this.setState({
                    selectedAppointment: e, showDetailsPopup: false, showSlotsPopup: false, showDualApptConfirm: false, showApptConfirmPopup: true, showNoApptRescheduled: false
                })
            }
        } else {
            this.setState({
                currentAppointment: currentAppointment, apptType: apptType, allAppointments: allAppointments, showDetailsPopup: false, showSlotsPopup: false, showDualApptConfirm: true, showNoApptRescheduled: false
            });
        }
    }

    closeDualAppointmentConfirm(e, allAppointments) {
        let slotInputReq = this.createInputRequestForSlots(e);

        let deliveryType = e.characteristics && e.characteristics.deliveryType ? e.characteristics.deliveryType : null;
        this.setState({
            slotInputReq: slotInputReq, deliveryType: deliveryType, showDetailsPopup: false, showDualApptConfirm: false, showSlotsPopup: true, allAppointments: allAppointments, showNoApptRescheduled: false
        });
    }

    renderDualAppointmentConfirmPopup() {
        let currentAppointment = this.state.currentAppointment;
        let allAppointments = this.state.allAppointments;
        let apptType = this.state.apptType;
        this.diffDaysNbnTelstra = this.calculateDateDiffBetweenNBNandTelstra();

        if (utils.isMobileDevice() || utils.isIpadDevice()) {
            if (this.diffDaysNbnTelstra < 2) {
                return <TelstraAppointmentRescheduleMobile closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} accessToken={this.accessToken} currentAppointment={currentAppointment} apptType={apptType} allAppointments={allAppointments} closeDualAppointmentConfirm={this.closeDualAppointmentConfirm.bind(this)} dualApptConfirm={this.dualApptConfirm.bind(this)} />
            }
            return <DualAppointmentConfirmMobile accessToken={this.accessToken} currentAppointment={currentAppointment} apptType={apptType} allAppointments={allAppointments} closeDualAppointmentConfirm={this.closeDualAppointmentConfirm.bind(this)} dualApptConfirm={this.dualApptConfirm.bind(this)} />
        }
        else {
            if (this.diffDaysNbnTelstra < 2) {
                return <div className="appointment-reschedule-margin">
                    <TelstraAppointmentReschedule closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} accessToken={this.accessToken} currentAppointment={currentAppointment} apptType={apptType} allAppointments={allAppointments} closeDualAppointmentConfirm={this.closeDualAppointmentConfirm.bind(this)} dualApptConfirm={this.dualApptConfirm.bind(this)} />
                </div>
            }
            return <div className="appointment-reschedule-margin">
                <DualAppointmentConfirm accessToken={this.accessToken} currentAppointment={currentAppointment} apptType={apptType} allAppointments={allAppointments} closeDualAppointmentConfirm={this.closeDualAppointmentConfirm.bind(this)} dualApptConfirm={this.dualApptConfirm.bind(this)} />
            </div>
        }
    }

    renderAppointmentSlotsPopup() {
        let slotAPIRequest = this.state.slotInputReq;
        let allAppointments = this.state.allAppointments ? this.state.allAppointments : "";
        let orderSystem = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.orderSystem : null ;
        let isoDate = orderSystem && orderSystem === constants.ORDER_SYSTEM_RCRM ? slotAPIRequest.earliestRescheduleDate : utils.convertDateToAppointmentAPITimestamp(new Date()) ;
        //set dependent Telstra appointment earliest reschedule date
        if(this.dependentTelstraEarliestRescheduleDate) {
            isoDate = this.dependentTelstraEarliestRescheduleDate;
        }

        if (slotAPIRequest.appointmentType && slotAPIRequest.appointmentType.toLowerCase() === constants.ORDER_CATEGORY_TELSTRA) {
            this.timer = moment().add(5, 'minutes');
        }
        if (utils.isMobileDevice() || utils.isIpadDevice()) {
            return <AppointmentRescheduleAvailableSlotsMobile displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} closeApptErrorAndChangeAppt={this.closeApptErrorAndChangeAppt.bind(this)} accessToken={this.accessToken} isoDate={isoDate} hardwareDeliveryDate={this.hardwareDeliveryDate} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} onCloseSlots={this.closeApptSlotsPopup.bind(this)} slotInputReq={this.state.slotInputReq} appointmentDetails={this.props.appointmentDetails} appointmentSlots={this.props.appointmentSlots} fetchAppointmentDetailsApi={this.props.fetchAppointmentDetailsApi} fetchAppointmentSlotsApi={this.props.fetchAppointmentSlotsApi} fetchAppointmentSlotsApiAuth={this.props.fetchAppointmentSlotsApiAuth} allAppointments={allAppointments} dualApptConfirm={this.dualApptConfirm.bind(this)} />
        } else {
            return <div className="appointment-reschedule-margin">
                <Row collapse expanded className="no-margin">
                    <AppointmentRescheduleAvailableSlots displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} closeApptErrorAndChangeAppt={this.closeApptErrorAndChangeAppt.bind(this)} accessToken={this.accessToken} hardwareDeliveryDate={this.hardwareDeliveryDate} onCloseSlots={this.closeApptSlotsPopup.bind(this)} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} isoDate={isoDate} slotAPIRequest={slotAPIRequest} appointmentDetails={this.props.appointmentDetails} appointmentSlots={this.props.appointmentSlots} fetchAppointmentDetailsApi={this.props.fetchAppointmentDetailsApi} fetchAppointmentSlotsApi={this.props.fetchAppointmentSlotsApi} appointmentType={this.props.appointmentType} nbnAppointmentId={this.props.nbnAppointmentId} fetchAppointmentSlotsApiAuth={this.props.fetchAppointmentSlotsApiAuth} allAppointments={allAppointments} dualApptConfirm={this.dualApptConfirm.bind(this)} />
                </Row>
            </div>
        }

    }

    renderNoAppointmentRescheduled() {
        if (utils.isMobileDevice() || utils.isIpadDevice()) {
            return <NoAppointmentRescheduledMobile viewAppointmentsLandingPage={this.viewAppointmentsLandingPage.bind(this)} />
        } else {
            return <Row collapse expanded className="no-margin">
                <NoAppointmentRescheduled viewAppointmentsLandingPage={this.viewAppointmentsLandingPage.bind(this)} />
            </Row>
        }
    }

    closeApptSlotsPopup(e, allAppointments) {
        let telstraSelectedSlot = e;

        if (allAppointments.appointments && allAppointments.appointments.length > 1) {
            let telstraOldSlot = allAppointments.appointments && allAppointments.appointments[1] ? allAppointments.appointments[1] : null;
            this.telstraOldSlot = telstraOldSlot;
            if (telstraOldSlot && telstraSelectedSlot && telstraOldSlot.appointmentDetails
                && telstraOldSlot.appointmentDetails.appointmentDate && telstraSelectedSlot.appointmentDate
                && ((telstraOldSlot.appointmentDetails.appointmentDate !== telstraSelectedSlot.appointmentDate)
                    || (telstraOldSlot.appointmentDetails.appointmentDate === telstraSelectedSlot.appointmentDate && telstraOldSlot.appointmentDetails.slotStartTime !== telstraSelectedSlot.slotStartTime))) {

                this.createDualAppointmentsArrayForReschedule(telstraOldSlot, telstraSelectedSlot);

            }

        }

        this.setState({
            selectedAppointment: e, showDetailsPopup: false, showSlotsPopup: false, showDualApptConfirm: false, showApptConfirmPopup: true, showNoApptRescheduled: false
        })
    }

    renderAppointmenConfirmPopup() {
        if (utils.isMobileDevice() || utils.isIpadDevice()) {
            return <AppointmentRescheduleConfirmMobile displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} dualAppointments={this.dualApptsArr} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} accessToken={this.accessToken} onCloseApptConfirmAndChangeAppt={this.closeApptConfirmAndChangeAppt.bind(this)} onCloseConfirmApptKeepOld={this.closeApptConfirmPopupKeepOld.bind(this)} onCloseConfirmAppt={this.closeApptConfirmPopup.bind(this)} selectedAppointment={this.state.selectedAppointment} appointmentDetails={this.props.appointmentDetails} deliveryType={this.state.deliveryType} />
        } else {
            return <div className="appointment-reschedule-margin">
                <AppointmentConfirm displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} dualAppointments={this.dualApptsArr} accessToken={this.accessToken} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} onCloseApptConfirmAndChangeAppt={this.closeApptConfirmAndChangeAppt.bind(this)} onCloseConfirmApptKeepOld={this.closeApptConfirmPopupKeepOld.bind(this)} onCloseConfirmAppt={this.closeApptConfirmPopup.bind(this)} selectedAppointment={this.state.selectedAppointment} appointmentDetails={this.props.appointmentDetails} deliveryType={this.state.deliveryType} />
            </div>
        }

    }

    closeApptConfirmPopup() {
        this.setState({
            showDetailsPopup: false, showSlotsPopup: false, showDualApptConfirm: false, showApptConfirmPopup: false, showApptSuccessPopup: true, showNoApptRescheduled: false
        })
    }

    closeApptConfirmPopupKeepOld() {
        this.dualApptsArr = [];
        this.dependentTelstraEarliestRescheduleDate = null;
        this.setState({
            showDetailsPopup: true, showDualApptConfirm: false, showSlotsPopup: false, showApptConfirmPopup: false, showApptSuccessPopup: false, showNoApptRescheduled: false
        })
    }

    closeApptConfirmAndChangeAppt(apptId) {
        const { appointmentDetails: { appointmentDetails: { appointments } } } = this.props;
        const selectedAppt = appointments.find(appointment => appointment.appointmentId === apptId);
        let slotInputReq = this.createInputRequestForSlots(selectedAppt);
        this.setState({
            slotInputReq:slotInputReq ,showDetailsPopup: false, showSlotsPopup: true, showDualApptConfirm: false, showApptConfirmPopup: false, showApptSuccessPopup: false, showNoApptRescheduled: false
        })
    }

    closeApptErrorAndChangeAppt() {
        this.setState({
            showDetailsPopup: false, showSlotsPopup: true, showDualApptConfirm: false, showApptConfirmPopup: false, showApptSuccessPopup: false
        })
    }

    renderAppointmenSuccessPopup() {
        var errors = null;
        let slotAPIRequest = this.state.slotInputReq;
        let dualRescheduledAppts = this.dualApptsArr ? this.dualApptsArr.filter(appt => appt.appointmentSlot.id != null) : null;

        if (slotAPIRequest.appointmentType && slotAPIRequest.appointmentType.toLowerCase() === constants.ORDER_CATEGORY_TELSTRA) {
            var presentTime = moment();
            if (presentTime.isAfter(this.timer)) {
                errors = this.tle_error.errors;
            }
        }
        let evaluationID, orderSystem, portInFNN;
        if (this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails) {
            evaluationID = this.props.appointmentDetails.appointmentDetails.evaluationID
            orderSystem = this.props.appointmentDetails.appointmentDetails.orderSystem
            portInFNN = this.props.appointmentDetails.appointmentDetails.portInFNN
        }
        let transactionReferenceID = null;
        if(utils.isAppointOrchestratorEnabled() && constants.ORDER_SYSTEM_RCRM !== orderSystem) {
          transactionReferenceID = this.props.appointmentSlots.appointmentSlots?.transactionReferenceID;
        }
        this.timer = null;
        if (utils.isMobileDevice() || utils.isIpadDevice()) {
            return <AppointmentRescheduleCompletedMobileView displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} dualAppointments={dualRescheduledAppts} closeApptErrorAndChangeAppt={this.closeApptErrorAndChangeAppt.bind(this)} renderAppointmentPopup={this.renderAppointmentPopup} tle_error={errors} accessToken={this.accessToken} orderId={this.orderId} bypassCache={this.props.bypassCache} appointmentConfirm={this.props.appointmentConfirm} selectedAppointment={this.state.selectedAppointment} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} evaluationID={evaluationID} orderSystem={orderSystem} updateAppointmentConfirmApiAuth={this.props.updateAppointmentConfirmApiAuth} updateAppointmentConfirmApi={this.props.updateAppointmentConfirmApi} portInFNN={portInFNN} transactionReferenceID={transactionReferenceID} />
        } else {
            return <AppointmentRescheduleCompleted displayCompleteCTA={this.displayCompleteCTA} callBackUrl={this.callBackUrl} orderId={this.orderId} dualAppointments={dualRescheduledAppts} closeApptErrorAndChangeAppt={this.closeApptErrorAndChangeAppt.bind(this)} renderAppointmentPopup={this.renderAppointmentPopup} tle_error={errors} accessToken={this.accessToken} orderId={this.orderId} appointmentConfirm={this.props.appointmentConfirm} selectedAppointment={this.state.selectedAppointment} evaluationID={evaluationID} orderSystem={orderSystem} updateAppointmentConfirmApiAuth={this.props.updateAppointmentConfirmApiAuth} closeAppointmentCompleted={this.closeAppointmentCompleted.bind(this)} updateAppointmentConfirmApi={this.props.updateAppointmentConfirmApi} portInFNN={portInFNN} transactionReferenceID={transactionReferenceID} />
        }

    }
    closeAppointmentCompleted() {
        this.dualApptsArr = [];
        this.dependentTelstraEarliestRescheduleDate = null;
        this.setState({ showDetailsPopup: true, showDualApptConfirm:false, showSlotsPopup:false, showApptSuccessPopup: false, showApptConfirmPopup: false });
        /*this.setState({
            showDetailsPopup: true, showDualApptConfirm: false, showSlotsPopup: false, showApptConfirmPopup: false, showApptSuccessPopup: false, showNoApptRescheduled: false
        });*/
    }

    viewAppointmentsLandingPage() {
        this.setState({ showDetailsPopup: true, showDualApptConfirm: false, showSlotsPopup: false, showApptSuccessPopup: false, showApptConfirmPopup: false, showNoApptRescheduled: false });
    }

    calculateDateDiffBetweenNBNandTelstra() {
        let allAppointments = this.state.allAppointments;
        let selectedNbnApptDate = this.selectedNbnAppt && this.selectedNbnAppt.appointmentDate ? this.selectedNbnAppt.appointmentDate : null;
        let telstraCurrentApptDate = allAppointments.appointments[1] && allAppointments.appointments[1].appointmentDetails && allAppointments.appointments[1].appointmentDetails.appointmentDate ? allAppointments.appointments[1].appointmentDetails.appointmentDate : null;

        var nbnDate = new Date(selectedNbnApptDate);
        var telstraDate = new Date(telstraCurrentApptDate);
        this.diffDaysNbnTelstra = parseInt((telstraDate - nbnDate) / (1000 * 60 * 60 * 24), 10);
        return this.diffDaysNbnTelstra;
    }

    createInputRequestForSlots(e) {
        const { appointmentDetails: { appointmentDetails: { appointments } } } = this.props;

        let searchStartDate = this.getSlotSearchStartDate(e)
        let searchEndDate = this.getSlotSearchEndDate(appointments)

        let slotInputReq = {
            "appointmentType": e.appointmentType, "appointmentId": e.appointmentId,
            "euRequired": e.characteristics ? e.characteristics.euRequired : null,
            "appointmentDate": e.appointmentDetails.appointmentDate,
            "transactionReferenceID": e.transactionReferenceID ? e.transactionReferenceID : null,
            "appointment": {
                "priority": "STANDARD",
                "searchStartDate": searchStartDate,
                "searchEndDate": searchEndDate,
                "earliestRescheduleDate": e.appointmentDetails.earliestRescheduleDate ? e.appointmentDetails.earliestRescheduleDate : null,
            }
        }
        return slotInputReq;
    }

    getSlotSearchStartDate(e) {
        return this.dependentTelstraEarliestRescheduleDate ? this.dependentTelstraEarliestRescheduleDate : e.appointmentDetails.earliestRescheduleDate;
    }

    getSlotSearchEndDate(appointments) {
        let orderCreatedDate = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails && this.props.appointmentDetails.appointmentDetails.orderCreatedDate ? this.props.appointmentDetails.appointmentDetails.orderCreatedDate : null ;
        if (orderCreatedDate === null) {
            return orderCreatedDate;
        }
        let transferType = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails && this.props.appointmentDetails.appointmentDetails.transferType ? this.props.appointmentDetails.appointmentDetails.transferType : null ;

        if (this.isDualAppointment(appointments)) {
            return utils.convertDateToAppointmentAPITimestamp(utils.addDays(orderCreatedDate, 65))
        } else if (constants.TRANSFER_TYPE_LIST.includes(transferType)) {
            return utils.convertDateToAppointmentAPITimestamp(utils.addDays(orderCreatedDate, 45))
        } else {
            return utils.convertDateToAppointmentAPITimestamp(utils.addDays(orderCreatedDate, 60))
        }
    }

    isDualAppointment(appointments) {
        return appointments && appointments.length > 1
    }

    createDualAppointmentsArrayForReschedule(currentObj, updatedObj) {
        this.dualApptsArr = this.dualApptsArr.filter(obj => obj.appointmentId != currentObj.appointmentId);
        let evaluationID, orderSystem;
        if (this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails) {
            evaluationID = this.props.appointmentDetails.appointmentDetails.evaluationID
            orderSystem = this.props.appointmentDetails.appointmentDetails.orderSystem
        }
        this.dualApptsArr.push({
            "appointmentId": currentObj.appointmentId,
            "appointmentType": currentObj.appointmentType,
            evaluationID, orderSystem,
            "euRequired": currentObj.characteristics && currentObj.characteristics.euRequired ? currentObj.characteristics.euRequired : null,
            "transactionReferenceID": currentObj.transactionReferenceID,
            "orderId": currentObj.orderId,
            "appointmentSlot": {
                "id": updatedObj.id ? updatedObj.id : null,
                "date": updatedObj.appointmentDate,
                "slotStartTime": updatedObj.slotStartTime,
                "slotEndTime": updatedObj.slotEndTime,
                "slotType": (updatedObj.slotStartTime).split(" ").pop(),
                "timeZone": updatedObj.timezone
            },
            "currentAppointmentSlot": {
                "date": currentObj.appointmentDetails.appointmentDate,
                "slotStartTime": currentObj.appointmentDetails.slotStartTime,
                "slotEndTime": currentObj.appointmentDetails.slotEndTime
            }
        });
    }

}
export default Appointments;
