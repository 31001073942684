import React, {Component} from "react";
import {Row} from "react-foundation-components/lib/global/grid";
import AppointmentErrorMobile from "./AppointmentErrorMobile";
import * as constants from "./Constants";
import alertIcon from "../images/Alert Small@1x.svg";
import moment from "moment";
import * as analytics from "../analytics-utils";
import {isValidUrl, replaceMessageTextWithUrl} from "../utils";
import _ from 'lodash';
import { ActionButton, Surface, TextStyle} from '@able/react';

class AppointmentDetailsMobile extends Component {
    constructor(props) {
        super(props);
        this.renderAppointmentsData = this.renderAppointmentsData.bind(this);
        this.state = {PONRReached : false};
        this.days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        this.fetchAppointmentDetailsResponse = this.props.appointmentDetails;
        this.appointmentsData = this.fetchAppointmentDetailsResponse?.appointmentDetails;

    }

    render() {
        if(this.state.PONRReached){
            return <div> <AppointmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeAppointmentCompleted={this.props.closeAppointmentCompleted} orderSystem={this.appointmentsData?.orderSystem} errors={this.fetchAppointmentDetailsResponse.errors}/> </div>
        }
        else{
            return <div>
                {this.appointmentsData && this.appointmentsData?.appointments && this.appointmentsData.appointments.length > 0 ? this.renderAppointmentsData() : <AppointmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.appointmentsData?.orderSystem} errors={this.fetchAppointmentDetailsResponse.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/>}
            </div>
        }
    }

    renderAppointmentsData() {
        let appointments = [];
        let counters = 1;
        const { appointmentDetails } = this.props;
        appointments.push(<Row id="appointmentDeatils" key="appointmentDeatilsHeader" collapse expanded className="no-margin">

        </Row>);
        const apptType = appointmentDetails.appointmentDetails.appointments.length === 1 ? appointmentDetails.appointmentDetails.appointments[0].appointmentType : 'NBN + Telstra';
        console.log(apptType);
        if (sessionStorage.getItem('apptType') !== null) {
            sessionStorage.removeItem('apptType');
        }
        sessionStorage.setItem('apptType', apptType);
        analytics.addOmnitureObjectForEventClick(constants.APPT_DETAILS_PAGE_NAME_ANALYTICS);
        
        if (this.appointmentsData && this.appointmentsData.appointments !== undefined && this.appointmentsData.appointments.length>0) {
            let currObj = null;
            appointments.push(<div>{this.appointmentsData.appointments.length>1 ?
                <span id="current-appointments-header-mobile" className="appointment-current-appointment-heading-mobile">
                {constants.CURRENT_APPOINTMENTS}
               </span>
               :
               <span id="current-appointments-header-mobile" className="appointment-current-appointment-heading-mobile">
                {constants.CURRENT_APPOINTMENT}
               </span>
            }</div>)
            this.appointmentsData.appointments.forEach(e => {
                currObj = e;
                let date = new Date(Date.parse(e.appointmentDetails.appointmentDate));
                let calendarDate = this.days[date.getDay()] + " " + date.getDate() + " " + this.month[date.getMonth()];
                let startTime = e.appointmentDetails.appointmentDate + ', ' + e.appointmentDetails.slotStartTime;
                let endTime = e.appointmentDetails.appointmentDate + ', ' + e.appointmentDetails.slotEndTime;
                const inlineErrorMsg = this.getInlineErrorMessage(e, this.fetchAppointmentDetailsResponse.orderSystem);
                appointments.push(
                    <Row key={e.appointmentType + counters} id="appointmentDeatils" collapse expanded className="no-margin">
                        <div className="appointment-reschedule-margin-mobile">
                            <div id="appointment-manager-content" className="appointment-manager-content-slots">
                                <div id="appointment-manager-details-header-div" className="appointment-manager-details-header-div-mobile">
                                    <div className="appointment-manager-slots-slot-header">
                                        {this.appointmentsData.appointments.length>1 ?
                                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-mobile">
                                                <span className="appointment-manager-details-subheading-mobile">Appointment </span> 
                                                <span className="appointment-manager-details-subheading-mobile">{this.appointmentsData.appointments.indexOf(e)+1} of </span>
                                                <span className="appointment-manager-details-subheading-mobile">{this.appointmentsData.appointments.length}</span>
                                            </div>
                                            :
                                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-mobile">
                                                Appointment
                                            </div>
                                        }
                                        {e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA ?
                                            <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-light-mobile">
                                                {e.appointmentType.charAt(0).toUpperCase() + e.appointmentType.toLowerCase().slice(1)} technician
                                            </div>
                                            : <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-details-subheading-light-mobile">
                                                {e.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div id="appointment-slot-div" className="appointment-slot-div appointment-details-font-bold-details-mobile">
                                    {calendarDate}<br></br>
                                    {moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}
                                </div>
                                {
                                    inlineErrorMsg && (
                                        <div className="reschedule-inline-error-mobile" style={{ marginBottom: 20 }}>
                                            <span className="reschedule-ponr-text">{inlineErrorMsg}</span>
                                        </div>
                                    )
                                }
                                <div id="appointment-manager-slot-message">
                                    <div>
                                        <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-details-banner-message-content-column">
                                                    <img id="Service-Message-Icon" src={alertIcon} className="appointment-details-banner-message-info-box" />
                                            </div>
                                            <div className="appointment-details-banner-message-content-column">
                                                    {(e.characteristics && e.characteristics.euRequired) || (e.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA) ?
                                                        <span id="eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                                        :
                                                        <span id="non-eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                                    }
                                                </div>
                                        </TextStyle>
                                        </Surface>
                                    </div>
                                    <div className="rectangle"></div>
                                </div>
                            </div>
                        </div>
                    </Row>)

                { counters++ }
            })
            appointments.push(this.showPONRmessage(currObj));
            return appointments;
        }

        return <AppointmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeAppointmentCompleted={this.props.closeAppointmentCompleted} orderSystem={this.appointmentsData.orderSystem} errors={this.fetchAppointmentDetailsResponse.errors} />;

        
    }

    getInlineErrorMessage(appt, orderSystem) {
        let errorMsg = _.get(appt, 'errors[0].message.message1');
        const errorSecondaryMsg = _.get(appt, 'errors[0].message.message2');
        if (errorMsg && errorSecondaryMsg) {
            errorMsg += ' ' + errorSecondaryMsg;
        }
        
        if (errorMsg) {
            let messageUsUrl = (orderSystem && orderSystem === constants.ORDER_SYSTEM_RCRM) ? constants.MYTELSTRA_ONE_LINK : constants.MYTELSTRA_APP_URL;
            errorMsg = replaceMessageTextWithUrl(errorMsg, messageUsUrl);
        }

        return errorMsg;
    }

    showPONRmessage(e) {
        if (!e) return;

        if (this.appointmentsData.orderSystem !== constants.ORDER_SYSTEM_RCRM) {
            let date = new Date(Date.parse(e.appointmentDetails.appointmentDate));
            let calendarDate = this.days[date.getDay()] + " " + date.getDate() + " " + this.month[date.getMonth()];

            let todaysDate = new Date();
            let tomorrowsDate = new Date();
            tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
            todaysDate = new Date(Date.parse(todaysDate));
            tomorrowsDate = new Date(Date.parse(tomorrowsDate));

            var a = new Date(date);
            var b = new Date(tomorrowsDate);
            var diffDays = parseInt((b - a) / (1000 * 60 * 60 * 24), 10);

            let formattedTodaysDate = this.days[todaysDate.getDay()] + " " + todaysDate.getDate() + " " + this.month[todaysDate.getMonth()];
            let formattedTomorrowsDate = this.days[tomorrowsDate.getDay()] + " " + tomorrowsDate.getDate() + " " + this.month[tomorrowsDate.getMonth()];

            if (diffDays === 1) {
                return <div className="reschedule-ponr-div">
                    <span className="reschedule-ponr-text">{constants.PIK_ONLY_PONR1}</span>
                </div>
            }

            if (formattedTodaysDate === calendarDate) {
                return <div className="reschedule-ponr-div">
                    <span className="reschedule-ponr-text">{constants.PIK_ONLY_PONR2}</span>
                </div>
            }

            if (e.appointmentStatus && e.appointmentStatus.toLowerCase() === constants.COMPLETED) {
                return <div className="reschedule-ponr-div">
                    <span className="reschedule-ponr-text">{constants.PIK_ONLY_PONR3}</span>
                </div>
            }

            if (e.appointmentStatus && e.appointmentStatus.toLowerCase() === constants.CANCELLED) {
                return <div className="reschedule-ponr-div">
                    <span className="reschedule-ponr-text">{constants.PIK_ONLY_PONR4}</span>
                </div>
            }
        }
        // if all appointments have errors, then do not show the reschedule button
        if (this.appointmentsData.appointments.length === 1) {
            const errorMsg = _.get(this.appointmentsData, 'appointments[0].errors.0')
            if (!!errorMsg) return this.renderCloseButton();
        }

        if (this.appointmentsData.appointments.length === 2) {
            const errorMsg1 = _.get(this.appointmentsData, 'appointments[0].errors.0')
            const errorMsg2 = _.get(this.appointmentsData, 'appointments[1].errors.0')
            if (!!errorMsg1 && !!errorMsg2) return this.renderCloseButton();
        }
        return<div className="appointment-manager-detail-content-dual-mobile"> 
                <div >
                    {this.appointmentsData.appointments.length>1 ?
                    <div>
                        <ActionButton element="button" variant="MediumEmphasis" label="Reschedule" onClick={(event) => this.showApptSlotsMobile(e)} className="appointment-close-confirm-button-label" tabIndex="0" />
                    </div>
                    :
                    <div>
                        <ActionButton element="button" variant="MediumEmphasis" label="Reschedule appointment" onClick={(event) => this.showApptSlotsMobile(e)} className="appointment-close-confirm-button-label" tabIndex="0" />
                    </div>
                }
                    
                    </div>
            </div>
        
    }

    renderCloseButton() {
        if(this.props.displayCompleteCTA === 'false'){
            return null;
        } else {
            return  <div className="appointment-manager-detail-content-dual-mobile">
                        <div>
                            <ActionButton element="button" variant="MediumEmphasis" label="Close" onClick={() => this.closeAppointmentManager()} className="appointment-close-confirm-button-label" tabIndex="0" />
                        </div>
                    </div>;
        }
    }

    showApptSlotsMobile(currentObj) {
        analytics.addOmnitureObjectForEventClick(constants.APPT_DETAILS_PAGE_NAME_ANALYTICS, constants.RESCHEDULE_APPT_CLICK_APPTS_ANALYTICS, 'buttonClick');
        let nonPONR = true;
        for (let appt = 0; appt < this.appointmentsData.appointments.length; appt++) {
            if(this.appointmentsData.appointments[appt].appointmentType && this.appointmentsData.appointments[appt].appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA && this.appointmentsData.appointments[appt].reschedulePONR === true) {
                nonPONR = false;
                this.setState({ PONRReached: true });
                break;
            }
        }

        if(nonPONR) {
            if(this.appointmentsData.appointments.length > 1) {
                if(this.appointmentsData.appointments[0].reschedulePONR === true) {
                    this.props.onCloseDetailsMobile(this.appointmentsData.appointments[1]);
                } else {
                    this.props.dualApptConfirm(currentObj, this.appointmentsData, constants.APPT_CATEGORY_NBN);
                }
            } else {
                this.props.onCloseDetailsMobile(this.appointmentsData.appointments[0]);
            }
        }
    }

    closeAppointmentManager(){
        if (this.props.callBackUrl === 'ORDER_STATUS_TRACKER') {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId;
        } else if(isValidUrl(this.props.callBackUrl)) {
            window.location.href = this.props.callBackUrl + '?appointmentManagerStatus=UNSUCCESSFUL';
        } else {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId;
        }
    }
}
export default AppointmentDetailsMobile;
