import React, {Component} from "react";
import {Column, Row} from "react-foundation-components/lib/global/grid";
import * as constants from "./Constants";
import successIcon from "../images/Success@1x.svg";
import AppoinmentRescheduleError from "./AppoinmentRescheduleError";
import Spinner from "../Spinner/Spinner";
import {isValidUrl} from "../utils";
import * as analytics from "../analytics-utils";
import { ActionButton } from '@able/react';

class AppointmentRescheduleCompleted extends Component {
    constructor(props) {
        super(props);
        this.bypassCache = this.bypassCache.bind(this);
        let selectedAppointmentForConfirm = this.props.selectedAppointment;
       
        let slotStartTime = selectedAppointmentForConfirm.slotStartTime ? selectedAppointmentForConfirm.slotStartTime : null;
        let slotEndTime = selectedAppointmentForConfirm.slotEndTime ? selectedAppointmentForConfirm.slotEndTime : null;
        let appointmentDate = selectedAppointmentForConfirm && selectedAppointmentForConfirm.appointmentDate ? selectedAppointmentForConfirm.appointmentDate : null;
        let slotType = slotStartTime && slotStartTime.split(" ") ? slotStartTime.split(" ").pop() : "AM";
        let slotId = selectedAppointmentForConfirm && selectedAppointmentForConfirm.id ? selectedAppointmentForConfirm.id : null;
        this.accessToken = this.props.accessToken && this.props.accessToken !== undefined ? this.props.accessToken : null;
        this.orderCategory = localStorage.getItem('orderCategory');
        if(this.props.tle_error == null){
            const { evaluationID, orderSystem, portInFNN, transactionReferenceID } = this.props
            if(this.accessToken) {
                this.props.updateAppointmentConfirmApiAuth(this.accessToken, this.props.orderId, selectedAppointmentForConfirm.appointmentType, selectedAppointmentForConfirm.appointmentId, this.props.deliveryType,
                    appointmentDate, slotStartTime, slotEndTime, slotType, slotId, selectedAppointmentForConfirm.timezone, this.props.dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
            } else {
                this.props.updateAppointmentConfirmApi(this.props.orderId, selectedAppointmentForConfirm.appointmentType, selectedAppointmentForConfirm.appointmentId, this.props.deliveryType,
                    appointmentDate, slotStartTime, slotEndTime, slotType, slotId, selectedAppointmentForConfirm.timezone, this.props.dualAppointments, evaluationID, orderSystem, portInFNN, transactionReferenceID);
            }
        }
    }
    
    render() {
        let spinnerMessage = '';
        if((this.props.selectedAppointment && this.props.selectedAppointment.appointmentType === 'TELSTRA') || (this.props.dualAppointments && this.props.dualAppointments.filter(appt=>appt.appointmentType==='TELSTRA').length>0)) {
            spinnerMessage = constants.APPT_WAIT_MSG;
        }
        if(this.props.tle_error != null){
            return <div>
                    <AppoinmentRescheduleError displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} closeApptErrorAndChangeAppt={this.props.closeApptErrorAndChangeAppt} renderAppointmentPopup={this.props.renderAppointmentPopup} errors={this.props.tle_error} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/>
                </div>
        }
        let apptAPIResp = this.props.selectedAppointment ? this.props.selectedAppointment : null;
        const {appointmentConfirm} = this.props;

        //uncomment when running on local
         //let status = 200;
        
       let status = appointmentConfirm && appointmentConfirm.status ? appointmentConfirm.status : null;
        
        if (appointmentConfirm.isFetching) {
            return <div>
                <Row collapse expanded>
                    <Column large={2}>
                    </Column>
                </Row>
                <Row collapse expanded>
                    <Column largeCentered="centered" large={8}>
                        <div className="telstra-spinner-box">
                            <div>
                                <Spinner size="large" message={spinnerMessage}/>
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
        }
        else {
            return <div>
                    {status && status === 200 ? this.renderAppointmentsData(apptAPIResp) : <AppoinmentRescheduleError closeApptErrorAndChangeAppt={this.props.closeApptErrorAndChangeAppt} displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.props.orderSystem} renderAppointmentSlotsPopup={this.renderAppointmentSlotsPopup} renderAppointmentPopup={this.props.renderAppointmentPopup} errors={appointmentConfirm.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted}/>}
                </div>
        }
         
    }
    renderAppointmentsData(apptAPIResp) {
        analytics.addOmnitureObjectForEventClick(constants.APPT_RESCHEDULE_SUCCESS_PAGE_NAME_ANALYTICS);
     return <Row collapse expanded className="no-margin">
        {apptAPIResp ?
            <div>
                <div className="appointment-manager-content">
                    <div className = "appointment-manager-success-icon-div-desktop">
                        <img id="Appointment-Reschedule-Success-Icon-Desktop" className="appointment-manager-sucess-icon-desktop" src={successIcon}/>
                    </div>
                    <div id="Appointment-Reschedule-Success-Message" className="appointment-manager-success-message-div-desktop">
                        <span className="appointment-manager-sucess-message-desktop">{constants.APPPOINMENT_RESCHEDULE_SUCCESS_HEADER}</span>
                    </div>
                    <div id="Appointment-Reschedule-Email-Message" className="appointment-manager-success-email-message-div-desktop">
                        <span className="appointment-manager-success-email-message-desktop">{constants.APPPOINMENT_RESCHEDULE_SUCCESS_MSG}</span>
                    </div>
                    {this.props.displayCompleteCTA === 'false' ? null
                    :
                    <div className="appointment-completed-div-desktop">
                        <ActionButton element="button" variant="MediumEmphasis" label="Done" id="Dont-change-appt" tabIndex="0"  onClick={() => this.bypassCache()} />
                    </div>}
                </div>
            </div>
            :<AppoinmentRescheduleError displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} openAppointmentDetailsPopup={this.props.openAppointmentDetailsPopup} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />
        }
        </Row>
    }
    bypassCache(){
        if (this.props.callBackUrl === 'ORDER_STATUS_TRACKER') {
            if(this.orderCategory && this.orderCategory !== undefined && this.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN) {
                window.parent.postMessage(constants.APPT_OVERRIDDEN_LISTENER, '*');
            } else {
                window.parent.postMessage(constants.APPT_RESCHEDULED_LISTENER, '*');
            }
            
        } else if(isValidUrl(this.props.callBackUrl)) {
            window.location.href = this.props.callBackUrl + '?appointmentManagerStatus=SUCCESS';
        } else {
            window.location.href = window.env.REACT_APP_DEFAULT_REDIRECT_URL + "/orderdetails/"+ this.props.orderId + '?overrideAppointment=true';
        }
    }

}
export default AppointmentRescheduleCompleted;