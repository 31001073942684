import React from "react";
import {Column, Row} from "react-foundation-components/lib/global/grid";
import * as utils from "../utils";
import * as constants from "./Constants";
import Spinner from "../Spinner/Spinner";
import moment from "moment";
import alertIcon from "../images/Alert Small@1x.svg";
import AppointmentManagerCalendarPopup from "./AppointmentManagerCalendarPopup";
import AppointmentErrorMobile from "./AppointmentErrorMobile";
import * as analytics from "../analytics-utils";
import { ActionButton, Surface, TextStyle} from '@able/react';

class AppointmentRescheduleAvailableSlotsMobile extends React.Component {   
    constructor(props) {
        super(props);  
        this.isMobileDevice = this.isMobileDevice.bind(this);
        this.isIpadDevice = this.isIpadDevice.bind(this);
        this.getClassNameForDevice = this.getClassNameForDevice.bind(this);
        let slotInputReq = this.props.slotInputReq;
        this.state = { dateClicked: new Date(), dateChanged: false, appointmentId: slotInputReq.appointmentId, 
            showMore: false, showPopup: false,
            appointmentType: slotInputReq.appointmentType, euRequired: slotInputReq.euRequired, showCalendar: false, showReschedulePopup:false, showMoreHidden: false}
        this.accessToken = this.props.accessToken && this.props.accessToken !== undefined ? this.props.accessToken : null;

        this.earliestRescheduleDate = null;
        this.days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        this.month=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        this.nbnAppointmentDate = this.getNBNAppointmentDate(this.props.appointmentDetails.appointmentDetails);
        this.calendarDate = null;  
        this.orderSystem = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.orderSystem : null;
        this.evaluationID = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.evaluationID : null;
        this.portInFNN = this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails ? this.props.appointmentDetails.appointmentDetails.portInFNN : null;
        this.appointment = slotInputReq.appointment ? slotInputReq.appointment : null;
        this.transactionReferenceID = slotInputReq.transactionReferenceID ? slotInputReq.transactionReferenceID : null;

        this.appointmentAddressState = null;
        if(this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails
            && this.props.appointmentDetails.appointmentDetails.appointments
            && this.props.appointmentDetails.appointmentDetails.appointments[0]
            && this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentAddress
            && this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentAddress.state ) {
                this.appointmentAddressState = this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentAddress.state;
            }

            if(this.accessToken) {	
                this.props.fetchAppointmentSlotsApiAuth(this.accessToken, slotInputReq.appointmentType, slotInputReq.appointmentId, this.props.isoDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);	
            } else {
                this.props.fetchAppointmentSlotsApi(slotInputReq.appointmentType, slotInputReq.appointmentId, this.props.isoDate, this.appointmentAddressState, this.hardwareDeliveryDate, this.nbnAppointmentDate,this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);	
            }
    }

    getNBNAppointmentDate(appointmentDetail){
        if(appointmentDetail && appointmentDetail.appointments){
            let appointmentArr = appointmentDetail.appointments;
            appointmentArr.forEach(element => {
                let appointmentType = element.appointmentType;
                if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN){
                    if(element.appointmentDetails && element.appointmentDetails.appointmentDate){
                        return element.appointmentDetails.appointmentDate;
                    }
                }
            });
        }
        return null;

    }

    render() {
        let spinnerMessage = '';
        if((this.props.appointmentDetails && this.props.appointmentDetails.appointmentDetails) && (this.props.appointmentDetails.appointmentDetails.appointments.length > 1 || this.props.appointmentDetails.appointmentDetails.appointments[0].appointmentType === 'TELSTRA')) {
            spinnerMessage = constants.APPT_WAIT_MSG;
        }

        let slotInputReq = this.props.slotInputReq;
        let apptAPIResp = this.props.appointmentSlots && this.props.appointmentSlots.appointmentSlots ? this.props.appointmentSlots.appointmentSlots : null;
        //FOR NBN appt
        // const apptAPIResp = {
        //      "slots": [
        //          {
        //              "date": "Sunday, 01 December 2019",
        //              "slotStartTime": "8:30 AM",
        //              "slotEndTime": "12:30 PM"
        //          },
        //          {
        //              "date": "Tuesday, 03 December 2019",
        //              "slotStartTime": "2:30 PM",
        //              "slotEndTime": "6:30 PM"
        //          },
        //          {
        //              "date": "Wednesday, 11 December 2019",
        //              "slotStartTime": "8:30 AM",
        //              "slotEndTime": "12:30 PM"
        //          },
        //          {
        //              "date": "Wednesday, 12 December 2019",
        //              "slotStartTime": "8:30 AM",
        //              "slotEndTime": "12:30 PM"
        //          },
        //          {
        //              "date": "Friday, 13 December 2019",
        //              "slotStartTime": "8:30 AM",
        //              "slotEndTime": "12:30 PM"
        //          }
        //      ]
        //  };
        
        //FOR Telstra appt
        // const apptAPIResp = {
        //         "slots": [
        //             {
        //                 "date": "Wednesday, 18 March 2020",
        //                 "slotStartTime": "8:00 AM",
        //                 "slotEndTime": "12:00 PM"
        //             },
        //             {
        //                 "date": "Wednesday, 18 March 2020",
        //                 "slotStartTime": "1:00 PM",
        //                 "slotEndTime": "5:00 PM"
        //             },
        //             {
        //                 "date": "Thursday, 19 March 2020",
        //                 "slotStartTime": "8:00 AM",
        //                 "slotEndTime": "12:00 PM"
        //             },
        //             {
        //                 "date": "Thursday, 19 March 2020",
        //                 "slotStartTime": "1:00 PM",
        //                 "slotEndTime": "5:00 PM"
        //             },
        //             {
        //                 "date": "Friday, 20 March 2020",
        //                 "slotStartTime": "8:00 AM",
        //                 "slotEndTime": "12:00 PM"
        //             },
        //             {
        //                 "date": "Friday, 20 March 2020",
        //                 "slotStartTime": "1:00 PM",
        //                 "slotEndTime": "5:00 PM"
        //             }
        //         ]
        //     };

        
        const { appointmentSlots } = this.props;
        
        let date =  null;
        if(apptAPIResp && apptAPIResp.slots && apptAPIResp.slots[0] && apptAPIResp.slots[0].date){
            date = new Date(Date.parse(apptAPIResp.slots[0].date));
            this.calendarDate = date.getDate() + " " + this.month[date.getMonth()];
        }
        if(apptAPIResp && apptAPIResp.earliestRescheduleDate){
            this.earliestRescheduleDate = apptAPIResp.earliestRescheduleDate;
        } else if( this.props.isoDate) {
            //set earliestRescheduleDate for APLAR response
            this.earliestRescheduleDate = new Date(Date.parse(this.props.isoDate));
        }

        if (appointmentSlots.isFetching) {
            return <div> 
            <Row collapse expanded>
                <Column largeCentered="centered" large={8}>
                    <div className="telstra-spinner-box-mobile">
                        <div>
                            <Spinner size="large" message={spinnerMessage}/>
                        </div>
                    </div>
                </Column>
            </Row></div>           
        }
        else {
            return <div>
                   {appointmentSlots && !appointmentSlots.isError && apptAPIResp && apptAPIResp.slots ? this.renderAppointmentsData(apptAPIResp, slotInputReq) : <AppointmentErrorMobile displayCompleteCTA={this.props.displayCompleteCTA} callBackUrl={this.props.callBackUrl} orderId={this.props.orderId} orderSystem={this.orderSystem} closeApptErrorAndResetDate={this.closeApptErrorAndResetDate.bind(this)} closeApptErrorAndChangeAppt={this.props.closeApptErrorAndChangeAppt} openAppointmentDetailsMobileView={this.props.openAppointmentDetailsMobileView} errors={appointmentSlots.errors} closeAppointmentCompleted={this.props.closeAppointmentCompleted} />}
                </div>
        }
    }

    renderAppointmentsData(apptAPIResp, slotInputReq) {
        const pageName = slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_NBN ? constants.APPT_AVLBL_NBN_SLOTS_PAGE_NAME_ANALYTICS : constants.APPT_AVLBL_TELSTRA_SLOTS_PAGE_NAME_ANALYTICS;
        analytics.addOmnitureObjectForEventClick(pageName);
        let allAppointments = this.props.allAppointments;
        var showMore = this.state.showMore;
        let lastAppointmentSlot  = apptAPIResp.slots[apptAPIResp.slots.length-1].date;
        let todayDate = new Date(moment());
                let day = todayDate.getDate() + " " + this.month[todayDate.getMonth()];
         
      let {dateClicked}= this.state;
                let selectedDate = dateClicked.getDate()+ " " + this.month[dateClicked.getMonth()];

        return <Row collapse expanded className="no-margin">
        
        <div id="appointment-manager-content-slots" className="appointment-manager-content-slots">
            <div id="appointment-manager-slots-slots-header-div" className="appointment-manager-slots-slot-header-div">
                <div className="appointment-manager-slots-slot-header">
                    {allAppointments && allAppointments.appointments && allAppointments.appointments.length > 1 ? 
                        <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-heading-mobile">
                            <span className="appointment-manager-slots-heading-mobile">Appointment </span> 
                            {slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_NBN?
                              <span className="appointment-manager-slots-heading-mobile">1 of </span>
                            : <span className="appointment-manager-slots-heading-mobile">2 of </span>
                            }
                            <span className="appointment-manager-slots-heading-mobile">{allAppointments.appointments.length}</span>
                        </div>
                        :
                        <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-heading-mobile">
                            Appointment
                        </div>
                    }
                    {slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA?
                        <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-heading-light-mobile">
                            {slotInputReq.appointmentType.charAt(0).toUpperCase()+slotInputReq.appointmentType.toLowerCase().slice(1)} technician
                        </div>
                    :    <div id="appointment-manager-slots-calendar-heading" className="appointment-manager-slots-heading-light-mobile">
                            {slotInputReq.appointmentType.toLowerCase()} {constants.NBN_CO_TECHNICIAN_TEXT}
                        </div>
                    }    
                </div>
            </div>
        
            <div id="appointment-manager-slot-message">
                <div>
                    <Surface
                                        variant="SurfaceSlight"
                                        className="eg-surface appointment-details-banner-message-content"
                                        interactive={undefined}
                                        id="non-eu-msg"
                                        >
                                        <TextStyle
                                            alias="TextBigBodyShort"
                                            element="p"
                                            colour="Default"
                                            alignment="Left"
                                            className=""
                                        >
                                           <div className="appointment-manager-slot-message-content-column">
                                <img id="Service-Message-Icon" src={alertIcon} className="appointment-manager-slot-info-box" />
                            </div>
                            <div className="appointment-manager-slot-message-content-column">
                                {slotInputReq.euRequired || (slotInputReq.appointmentType.toLowerCase() == constants.ORDER_CATEGORY_TELSTRA) ?
                                    <span id="eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_EU_MSG}</span>
                                    :
                                    <span id="non-eu-msg" className="eu-msg-slots-mobile">{constants.APPOINTMENT_RESCHEDULE_NON_EU_MSG}</span>
                                }
                            </div>
                                        </TextStyle>
                                        </Surface>
                </div>
                <div className="rectangle"></div> 
            </div>

            <div id="appointment-manager-change-date" className="appointment-manager-slots-change-date-mobile">
                <span id="calendarOpenText" className="calendarOpenText">
                    Available appointments from <br/> 
                    <span className="calendarOpenText">{this.state.dateChanged? selectedDate : day}</span>
                </span>
                <br/>
                <div className="appointment-slots-click-here-link-mobile">
                    <ActionButton id="calendarOpenLink" className="calendarOpenLink" element="button" variant="LowEmphasis" label="Change" tabIndex="0"  onClick={this.togglePopup.bind(this)} />
                </div>
            </div>

            {this.state.showPopup ?
                <AppointmentManagerCalendarPopup earliestRescheduleDate={this.earliestRescheduleDate} changeDate={this.changeDate.bind(this)}
                    closePopup={this.togglePopup.bind(this)}
                />
                : null
            }

            <hr className="appointment-manager-slots-divider"/>
            
            {this.renderAppointmentSlot(apptAPIResp, apptAPIResp.slots.length, allAppointments)}
            
            {
            this.state.showMore == true?
                <a id="showMoreButton" className="show-more-mobile-button" onClick={(e)=>this.searchAppt(lastAppointmentSlot)} >
                    <div className="show-more-button-mobile">
                        <span className="reschedule-text-mobile">See more + </span>
                        {/* <img id="seeMore"  className="showMoreIcon" src={showMoreIcon} alt="Show More Icon" /> */}
                    </div>
                </a>:
                <a id="showMoreButton" className="show-more-mobile-button" onClick={() => this.setState({ showMore: true })}>
                    <div className="show-more-button-mobile">
                        <span className="reschedule-text-mobile">See more + </span>
                        {/* <img id="seeMore"  className="showMoreIcon" src={showMoreIcon} alt="Show More Icon" /> */}
                    </div> 
                </a>
            }
        </div>
    </Row>
    }

    closeApptErrorAndResetDate(){
        this.setState({
            dateClicked: new Date()
        });
    }

    togglePopup() { 
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    changeDate(changedDate){
        this.setState({
            dateClicked: changedDate,
            dateChanged: true,
            showPopup: !this.state.showPopup
        });

    }

    renderAppointmentSlot(apptAPIResp, slotsCounter, allAppointments){
        let appointmentSlots = [];
        let showMore = this.state.showMore;
        let slotsNum = showMore?slotsCounter:3;
        let timezone = apptAPIResp.timeZone ? apptAPIResp.timeZone : null;
        let appointmentType = apptAPIResp.appointmentType;
        if(apptAPIResp.slots.length < 3){
            slotsNum = apptAPIResp.slots.length;
        }

        for (let counter = 0; counter < slotsNum; counter++) {
            let appointmentSlotCurrent = apptAPIResp.slots[counter]; 
            let date = new Date(Date.parse(appointmentSlotCurrent.date));
            let calendarDate = this.days[date.getDay()] + " " + date.getDate() + " " + this.month[date.getMonth()];
            let startTime = appointmentSlotCurrent.date + ', ' + appointmentSlotCurrent.slotStartTime;
            let endTime = appointmentSlotCurrent.date + ', ' + appointmentSlotCurrent.slotEndTime;
            appointmentSlots.push(<div key={"appointment-slot-" + counter} id="apppointment-slot" onClick={(e)=>this.reschedule(e, appointmentSlotCurrent, timezone, appointmentType, allAppointments)}>
                <div>
                    <div id="non-eu-msg">
                        <div className="appointment-manager-slot-list-content-row-mobile">
                            <div className="appointment-manager-slot-list-content-column1">
                                <span className="appointment-manager-slot-list-text-date">{calendarDate}</span>
                                <br/>
                                <div className="appointment-slots-date-time-padding-mobile">
                                    <span className="appointment-manager-slot-list-text-time">{moment(startTime).format('h:mma')} to {moment(endTime).format('h:mma')}</span>
                                </div>
                            </div>
                            <div className="appointment-manager-slot-list-content-column2">
                            {">"}     
                            </div>
                        </div>  
                    </div>
                </div>
                <hr className="appointment-manager-slots-divider"/> 
            </div>); 
        }
        return <div> {appointmentSlots} </div>;
    }

    componentDidUpdate(prevProps, prevState){
        let slotInputReq = this.props.slotInputReq;
        if(prevState.dateClicked !== this.state.dateClicked)
        {
            let fromDate = utils.convertDateToAppointmentAPITimestamp(this.state.dateClicked)
            this.appointment.searchStartDate = fromDate;
            if(this.accessToken) {
                this.props.fetchAppointmentSlotsApiAuth(this.accessToken, slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.props.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
            } else {
                this.props.fetchAppointmentSlotsApi(slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.props.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
            }
           
        }
    }

    componentWillUpdate(nextProps, nextState){
        if(nextProps && nextProps.appointmentSlots && nextProps.appointmentSlots.appointmentSlots && nextProps.appointmentSlots.appointmentSlots.slots && nextProps.appointmentSlots.appointmentSlots.slots[0] && nextProps.appointmentSlots.appointmentSlots.slots[0].date){
            let date = new Date(Date.parse(nextProps.appointmentSlots.appointmentSlots.slots[0].date));
            this.calendarDate = date.getDate() + " " + this.month[date.getMonth()];
        }
        return true;
    }

    reschedule(e, appointmentSlotCurrent, timezone, appointmentType, allAppointments){
        let slotInputReq = this.props.slotInputReq;
        let appointmentDate = e.target.innerText;
        let selectedSlot = {appointmentId: slotInputReq.appointmentId, appointmentType:slotInputReq.appointmentType, appointmentDate: appointmentSlotCurrent.date, slotStartTime: appointmentSlotCurrent.slotStartTime, slotEndTime: appointmentSlotCurrent.slotEndTime, id:appointmentSlotCurrent.id, euRequired:slotInputReq.euRequired, deliveryType:slotInputReq.deliveryType, timezone: timezone, dependentAppointments: appointmentSlotCurrent.dependentAppointments}
        
        if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN && allAppointments && allAppointments.appointments && allAppointments.appointments.length > 1) {
            this.props.dualApptConfirm(allAppointments.appointments[0], allAppointments, constants.APPT_CATEGORY_TELSTRA, selectedSlot)
        } else {
            this.props.onCloseSlots(selectedSlot, allAppointments);
        }        
        this.setState({ showReschedulePopup: !this.state.showReschedulePopup });
    }

    searchAppt(appointmentDate){
        var date = new Date(appointmentDate);
        let slotInputReq = this.props.slotInputReq;
        let fromDate = utils.convertDateToAppointmentAPITimestamp(date)
        this.appointment.searchStartDate = fromDate;   
        if(this.accessToken) {
            this.props.fetchAppointmentSlotsApiAuth(this.accessToken, slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate,this.appointmentAddressState, this.props.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
        } else {
            this.props.fetchAppointmentSlotsApi(slotInputReq.appointmentType, slotInputReq.appointmentId, fromDate, this.appointmentAddressState, this.props.hardwareDeliveryDate, this.nbnAppointmentDate, this.evaluationID, this.orderSystem, this.portInFNN, this.transactionReferenceID, this.appointment);
        }        
    }

    isMobileDevice() {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        }
    }

    isIpadDevice() {
        if(navigator.userAgent.match(/iPad/i)) {
            return true;
        }
    }

    getClassNameForDevice() {
        
        let devicelClassName = "popup-inner desktop-layout";
        if(this.isMobileDevice()) {
            devicelClassName = "popup-inner mobile-layout";
        } else if(this.isIpadDevice()) {
            devicelClassName = "popup-inner ipad-layout";
        }

        return devicelClassName;
    }
}

export default AppointmentRescheduleAvailableSlotsMobile;
